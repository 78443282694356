/* @flow */

import * as React from 'react';
import './sidebar-user-avatar.css';

type Props = {
    avatarUrl: string,
    initials: string,
    size: number,
    isActive?: boolean,
    className?: string,
};

export class SidebarUserAvatar extends React.Component<Props> {
    render() {
        const style = {
            width: `${this.props.size}px`,
            height: `${this.props.size}px`,
            lineHeight: `${this.props.size}px`,
            fontSize: Math.floor(this.props.size / 2 - this.props.size / 15),
            boxShadow: 'none',
            flexShrink: 0,
            borderRadius: `${this.props.size}px`,
        };

        return (
            <div
                styleName={this.props.isActive ? 'container-active' : 'container'}
                className={this.props.className}
                style={style}
            >
                {this.props.avatarUrl ? (
                    <img style={style} src={`${this.props.avatarUrl}?width=100&height=100`} />
                ) : (
                    undefined
                )}
                <div style={style} styleName='initials'>
                    {this.props.initials}
                </div>
            </div>
        );
    }
}
