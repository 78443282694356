/* @flow */
import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

import {fetchList} from 'nutshell-core/entities/fetch-list';
import {safelyParseError} from 'nutshell-core/utils';
import type {ReportsResponse} from 'nutshell-core/types';
import {
    jsonToTimeseriesReportData,
    jsonToReportTableData,
    jsonToReportTableTotalsData,
    addReferenceData,
} from 'nutshell-core/json-to-report-data';

import {
    updateFormSubmissionsListData,
    failFormSubmissionsListData,
    updateFormEngagementReportTableData,
    updateFormEngagementReportChartData,
    failFormEngagementReportChartData,
    type FormEngagementReportDataRequestedAction,
    type FormSubmissionsListRequestedAction,
} from './forms-actions';

import {getEngagementReportPostParams, type FormEngagementReportPostParams} from './forms-utils';

export const requestFormSubmissionsListEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('FORM_SUBMISSIONS_LIST_DATA_REQUESTED')
        .switchMap((action: FormSubmissionsListRequestedAction) => {
            const {filters, pageNum = 1, columns, sort, q} = action.payload;

            // Our pagination components aren't 0-based, but this specific
            // request expects page 0 to be the first page. Using Math.max() here
            // in case older URLs with page 0 are still in the wild
            const adjustedPageNum = Math.max(0, pageNum - 1);

            const listRequestStream = Observable.fromPromise(
                fetchList('contacts', {
                    filter: filters,
                    sort,
                    page: {page: adjustedPageNum},
                    fields: columns,
                    q,
                })
            );

            const formPeopleListDataStream = listRequestStream.map((response) =>
                updateFormSubmissionsListData(response)
            );

            return formPeopleListDataStream.catch((err) => {
                const safeError = safelyParseError(err);

                return Observable.of(failFormSubmissionsListData(safeError));
            });
        });

export const requestFormEngagementReportDataEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('FORM_ENGAGEMENT_REPORT_DATA_REQUESTED')
        .switchMap((action: FormEngagementReportDataRequestedAction) => {
            const {reportParams, filters} = action.payload;
            const params = getEngagementReportPostParams(reportParams, filters);

            const requestStream = Observable.fromPromise(fetchData(params));
            const reportDataStream = requestStream.map(transformDataForReport);

            const reportTableDataStream = reportDataStream.map(({tableData}) =>
                updateFormEngagementReportTableData(tableData)
            );

            const reportChartDataStream = reportDataStream
                .map((data) => data.chartData)
                .filter((chartData) => Boolean(chartData))
                .map((chartData) => updateFormEngagementReportChartData({chartData}));

            return reportChartDataStream
                .merge(reportTableDataStream)
                .catch((err) => Observable.of(failFormEngagementReportChartData(err)));
        });

function fetchData(params: FormEngagementReportPostParams) {
    return Promise.all([fetchFormEngagementReportData(params)]);
}

function fetchFormEngagementReportData(params: FormEngagementReportPostParams): Promise<any> {
    const url = '/rest/forms/report';
    const ajaxParams = {
        url,
        dataType: 'json',
        data: params,
    };

    return ($.ajax(ajaxParams): any); // This is how we have to convince flow that the response is a ReportsResponse
}

// Taken from report-epics
function transformDataForReport(results: ReportsResponse[]) {
    let chartData;
    const currentResult = results[0];
    if (!currentResult || !Array.isArray(currentResult.reports))
        return {chartData: null, tableData: null};
    const comparisonResult = results.length > 1 && results[1];

    const currentChartData = jsonToTimeseriesReportData(currentResult);
    if (comparisonResult) {
        const referenceData = jsonToTimeseriesReportData(comparisonResult);
        chartData = addReferenceData(currentChartData, referenceData);
    } else {
        chartData = currentChartData;
    }

    const rows = jsonToReportTableData(currentResult);
    const totals = jsonToReportTableTotalsData(currentResult);
    const tableData = {rows, totals};

    return {chartData, tableData};
}
