/* @flow */

import * as React from 'react';
import {components, type OptionProps} from 'react-select';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';

import {Avatar} from 'shells/avatar';

import {useGetRelatedEntities} from './hooks/use-get-related-entities';
import {GraphQLSelectInline} from './graphql-select-inline';

import './graphql-select-options.css';

type Option = {id: string, name: string};

type Props = {
    selectedValues: Option[],
    onChange: (values: Option | Option[]) => any,
    onClearValue?: () => void,
    isMulti: boolean,
    clearable?: boolean,
    disabled?: boolean,
};

export function GraphQLSelectEntities(props: Props) {
    const {fetchMore, isLoading} = useGetRelatedEntities();

    const handleLoadOptions = (search: ?string) => {
        return fetchMore(search).then(({data}) => {
            const quickResults = data.search ? data.search.quickResults : null;

            let topResults = quickResults ? quickResults.topResults : [];

            if (topResults.length === 0) {
                const recentResults =
                    quickResults && quickResults.recentResults ? quickResults.recentResults : [];
                if (recentResults && recentResults.length > 0) {
                    // only first 5
                    topResults = recentResults.slice(0, 5);
                }
            }

            return Promise.resolve(topResults);
        });
    };

    return (
        <GraphQLSelectInline
            placeholder='Type to search…'
            value={props.selectedValues}
            multi={props.isMulti}
            onClearValue={props.onClearValue}
            loadOptions={handleLoadOptions}
            onChange={props.onChange}
            onBlur={() => {
                props.onChange(props.selectedValues);
            }}
            noOptionsMessage={({inputValue}) => {
                if (isLoading) {
                    return 'Getting recent results…';
                }
                if (inputValue.length) {
                    return `Nothing found matching '${inputValue}'`;
                }
                if (inputValue.length === 0) {
                    return 'No recent results. Type to search…';
                }

                return null;
            }}
            components={{Option: OptionComponent}}
            labelKey='name'
            valueKey='id'
            clearable={props.clearable}
            shouldStyleValueByType={true}
            disabled={props.disabled}
        />
    );
}

const OptionComponent = (props: OptionProps) => {
    // This is a hack to get around limitations in our old version of flow
    const spreadableProps: $Exact<OptionProps> = (props: any);

    return (
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        <components.Option {...spreadableProps}>
            <div styleName='option-wrapper'>
                <Avatar
                    avatarUrl={props.data.avatarUrl}
                    initials={props.data.initials}
                    type={getApiTypeFromGraphQLTypename(props.data.__typename)}
                />
                <div styleName='option-label'>{props.data.name}</div>
            </div>
        </components.Option>
    );
};
