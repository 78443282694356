/* @flow */

import * as React from 'react';

import {colors} from '../colors';

import './icon-avatar.css';

const DEFAULT_SIZE = 30;

type Props = {|
    icon: React.ComponentType<*>,
    avatarSize?: number,
    iconSize?: number,
    backgroundColor?: string,
    iconColor?: string,
|};

/**
 * Renders the given icon inside a colored circle (Avatar).
 *
 * Default Avatar (circle) size is 30px and the default Icon size is half the avatar size (15px).
 * Default backgroundColor is offWhiteDk and default iconColor is grey.
 * Provided icon Component should take a size, wrapWithDiv and fill/color prop.
 */
export function IconAvatar(props: Props) {
    const {
        icon: Icon,
        avatarSize = DEFAULT_SIZE,
        iconSize = Math.floor(DEFAULT_SIZE / 2),
        backgroundColor = colors.offWhiteDk,
        iconColor = colors.grey,
    } = props;

    const style = {
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize,
        backgroundColor,
        color: iconColor,
    };

    return (
        <div style={style} styleName='icon-avatar'>
            <Icon size={iconSize} wrapWithDiv={false} fill={iconColor} color={iconColor} />
        </div>
    );
}
