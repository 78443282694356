/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import {CloseIcon} from '../../icon';
import styles from './close-button.css';

type Props = {
    size?: number,
    className?: string,
    tooltip?: string,
    getRef?: (?HTMLButtonElement) => void,
    onClick: (e: SyntheticEvent<*>) => void,
    variant?: 'dark' | 'medium' | 'light',
};

export class CloseButton extends React.PureComponent<Props> {
    static defaultProps = {
        size: 14,
        variant: 'dark',
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        ReactTooltip.hide();
    }

    render() {
        let containerStyles = '';
        switch (this.props.variant) {
            case 'light':
                containerStyles = styles['container--light'];
                break;
            case 'medium':
                containerStyles = styles['container--medium'];
                break;
            case 'dark':
                containerStyles = styles.container;
        }

        const className = this.props.className
            ? `${this.props.className} ${containerStyles}`
            : containerStyles;

        return (
            <button
                type='button'
                onClick={this.props.onClick}
                ref={this.props.getRef}
                data-tip={this.props.tooltip}
                className={className}
            >
                <CloseIcon size={this.props.size} />
            </button>
        );
    }
}
