/* @flow */

export const ActionTypes = {
    ACTIVE_FIELD_ID_SET: 'ACTIVE_FIELD_ID_SET',
};

type SetActiveFieldIdAction = {
    type: 'ACTIVE_FIELD_ID_SET',
    payload: {
        activeFieldId: ?string,
    },
};

export type ConnectedFormsAction = SetActiveFieldIdAction;

/*
 * Action creators
 */
export const setActiveFieldId = (activeFieldId: ?string): SetActiveFieldIdAction => ({
    type: ActionTypes.ACTIVE_FIELD_ID_SET,
    payload: {activeFieldId},
});
