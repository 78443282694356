/* @flow */

import {
    ActivityIcon,
    AccountIcon,
    AccountParentIcon,
    ChatIcon,
    CompanyTypeIcon,
    ConstantContactIcon,
    ContactAssigneeIcon,
    EmailIcon,
    EyeIcon,
    InboundArrowIcon,
    LeadCancelledIcon,
    LeadHotIcon,
    LeadIconChevron,
    LeadLostIcon,
    LeadPendingIcon,
    LinkClickIcon,
    MailchimpIcon,
    MeetingIcon,
    MessageIcon,
    NoteIcon,
    OutboundArrowIcon,
    OutreachIcon,
    PhoneCallIcon,
    SidebarIconAccountsIcon,
    SidebarIconContactsIcon,
    SparkleIcon,
    StarIcon,
    StepIcon,
    TicketIcon,
    VirtualMeetingIcon,
    VoicemailIcon,
    WebFormIcon,
    CommentIcon,
    CloudUploadIcon,
    SiteGlobeIcon,
    MicrophoneIcon,
    ZoomTextIcon,
} from '../../icon';

import type {TimelineEntryIconVariant, TimelineEntryIcon} from './types';

export const DEFAULT_ICON_SIZE = 19;
export const DEFAULT_SUB_ICON_SIZE = 11;

const TIMELINE_BG_COLOR = 'offWhite';
const DEFAULT_FILL_COLOR = 'grey';

export const TIMELINE_ENTRY_ICONS: {[key: TimelineEntryIconVariant]: TimelineEntryIcon} = {
    /* * * * * * * * * * * * * * * * * * * *
     * Activity icons
     * * * * * * * * * * * * * * * * * * * */
    'custom-activity': {
        icon: ActivityIcon,
        backgroundColor: 'reportNavy',
        size: 18,
    },
    'phone-call': {
        icon: PhoneCallIcon,
        backgroundColor: 'reportNavy',
        size: 18,
    },
    voicemail: {icon: VoicemailIcon, backgroundColor: 'reportNavy', size: 23},
    meeting: {icon: MeetingIcon, backgroundColor: 'reportNavy', size: 20},
    'virtual-meeting': {
        icon: VirtualMeetingIcon,
        backgroundColor: 'reportNavy',
        size: 18,
    },
    'recorded-activity': {
        icon: MicrophoneIcon,
        backgroundColor: 'reportNavy',
        size: 18,
    },
    'zoom-meeting': {
        icon: ZoomTextIcon,
        backgroundColor: 'reportNavy',
        size: 18,
    },
    /* * * * * * * * * * * * * * * * * * * *
     * Email icons
     * * * * * * * * * * * * * * * * * * * */
    'outbound-email': {
        icon: EmailIcon,
        subIcon: OutboundArrowIcon,
        backgroundColor: 'navyDk',
        size: 18,
    },
    'inbound-email': {
        icon: EmailIcon,
        subIcon: InboundArrowIcon,
        backgroundColor: 'navyDk',
        size: 18,
    },
    'automated-email': {
        icon: EmailIcon,
        subIcon: StepIcon,
        backgroundColor: 'navyDk',
        size: 18,
    },
    /* * * * * * * * * * * * * * * * * * * *
     * Entry icons
     * * * * * * * * * * * * * * * * * * * */
    note: {icon: NoteIcon, backgroundColor: 'productBgHovered', size: 17},
    ticket: {icon: TicketIcon, backgroundColor: 'plum'},
    chat: {icon: ChatIcon, backgroundColor: 'greyDk'},
    /* * * * * * * * * * * * * * * * * * * *
     * Entity created / deleted icons
     * * * * * * * * * * * * * * * * * * * */
    'company-created': {
        icon: SidebarIconAccountsIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 19,
    },
    'company-deleted': {
        icon: SidebarIconAccountsIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: 'placeholder',
        size: 19,
    },
    'person-created': {
        icon: SidebarIconContactsIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 19,
    },
    'person-deleted': {
        icon: SidebarIconContactsIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: 'placeholder',
        size: 19,
    },
    /* * * * * * * * * * * * * * * * * * * *
     * Lead status icons
     * * * * * * * * * * * * * * * * * * * */
    'lead-lost': {icon: LeadLostIcon, backgroundColor: 'roseDk'},
    // $FlowIgnore - Flow doesn't understand that the icon prop is optional
    'lead-won': {icon: StarIcon, backgroundColor: 'lead'},
    'lead-pending': {icon: LeadPendingIcon, backgroundColor: 'grey'},
    'lead-cancelled': {icon: LeadCancelledIcon, backgroundColor: 'grey'},
    'lead-opened': {icon: LeadIconChevron, backgroundColor: 'grey'},
    /* * * * * * * * * * * * * * * * * * * *
     * Lead priority icons
     * * * * * * * * * * * * * * * * * * * */
    'lead-hot': {icon: LeadHotIcon, backgroundColor: 'orange'},
    'lead-normal': {icon: LeadHotIcon, backgroundColor: 'grey'},
    /* * * * * * * * * * * * * * * * * * * *
     * Nutshell marketing icons
     * * * * * * * * * * * * * * * * * * * */
    'outreach-sent': {
        icon: OutreachIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
    },
    'outreach-opened': {
        icon: EyeIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 21,
    },
    'outreach-clicked': {
        icon: LinkClickIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
    },
    /* * * * * * * * * * * * * * * * * * * *
     * Other banner icons
     * * * * * * * * * * * * * * * * * * * */
    'form-submitted': {
        icon: WebFormIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
    },
    'assignee-changed': {
        icon: ContactAssigneeIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
    },
    'company-type-changed': {
        icon: CompanyTypeIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 18,
    },
    'account-parent-added': {
        icon: AccountParentIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 18,
    },
    'account-child-added': {
        icon: AccountIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 18,
    },
    'company-details-added': {
        icon: SparkleIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: 'plumDk',
        size: 18,
    },
    'lead-stage-advanced': {
        icon: StepIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 17,
    },
    'file-upload': {
        icon: CloudUploadIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 20,
    },
    comment: {
        icon: CommentIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 17,
    },
    /* * * * * * * * * * * * * * * * * * * *
     * Integration icons
     * * * * * * * * * * * * * * * * * * * */
    mailchimp: {
        icon: MailchimpIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 21,
    },
    'constant-contact': {
        icon: ConstantContactIcon,
        backgroundColor: TIMELINE_BG_COLOR,
        fill: DEFAULT_FILL_COLOR,
        size: 21,
    },
    /* * * * * * * * * * * * * * * * * * * *
     * Customer journey icons
     * * * * * * * * * * * * * * * * * * * */
    session: {
        icon: SiteGlobeIcon,
        backgroundColor: 'grey',
    },
    /* * * * * * * * * * * * * * * * * * * *
     * Inbox icons
     * * * * * * * * * * * * * * * * * * * */
    thread: {
        icon: MessageIcon,
        backgroundColor: 'reportOrange',
    },
};
