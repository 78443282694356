/* @flow */

import type {ReportGap, FilterObject} from 'nutshell-core/types';
import {serializeFilters} from 'nutshell-core';

export type FormEngagementChartParams = {
    gap: ReportGap,
    formId: string,
};

export type FormEngagementReportPostParams = {
    filter: Object,
    gap: ReportGap,
    formId: string,
};

export function getEngagementReportPostParams(
    reportParams: FormEngagementChartParams,
    filters: FilterObject[]
): FormEngagementReportPostParams {
    const {gap, formId} = reportParams;

    return {
        filter: serializeFilters(filters),
        gap,
        formId,
    };
}
