/* @flow */
import {createAction} from 'redux-act';
import type {Dispatch} from 'redux';

import type {FilterObject} from 'nutshell-core/types';
import * as Schema from 'nutshell-core/schema';
import type {ListSchemaResponse} from 'nutshell-core/schema/schema-types';

import type {
    FormsSchemaRequestedAction,
    FormsSchemaUpdatedAction,
    ConnectedFormsSchemaRequestedAction,
    ConnectedFormsSchemaUpdatedAction,
} from './forms-types';

export type FormSubmissionsListRequestedAction = {
    type: 'FORM_SUBMISSIONS_LIST_DATA_REQUESTED',
    payload: {
        filters: FilterObject[],
        pageNum: number,
        columns: string[],
        sort: string,
        q: string,
    },
};

export type FormEngagementReportDataRequestedAction = {
    type: 'FORM_ENGAGEMENT_REPORT_DATA_REQUESTED',
    payload: {
        reportParams: Object,
        filters: FilterObject[],
    },
};

export const toggleShowFilterDrawer = createAction('FORM_SUBMISSIONS_FILTER_DRAWER_TOGGLED');

// Form submission list
export const requestFormSubmissionsListData: (
    payload: $PropertyType<FormSubmissionsListRequestedAction, 'payload'>
) => FormSubmissionsListRequestedAction = createAction('FORM_SUBMISSIONS_LIST_DATA_REQUESTED');
export const updateFormSubmissionsListData = createAction('FORM_SUBMISSIONS_LIST_DATA_UPDATED');
export const failFormSubmissionsListData = createAction('FORM_SUBMISSIONS_LIST_DATA_FAILED');

// Form engagement report
export const requestFormEngagementReportData: (
    payload: $PropertyType<FormEngagementReportDataRequestedAction, 'payload'>
) => FormEngagementReportDataRequestedAction = createAction(
    'FORM_ENGAGEMENT_REPORT_DATA_REQUESTED'
);
export const updateFormEngagementReportTableData = createAction(
    'FORM_ENGAGEMENT_REPORT_TABLE_DATA_UPDATED'
);
export const updateFormEngagementReportChartData = createAction(
    'FORM_ENGAGEMENT_REPORT_CHART_DATA_UPDATED'
);
export const updateFormEngagementReportMaps = createAction('FORM_ENGAGEMENT_REPORT_MAPS_UPDATED');
export const failFormEngagementReportChartData = createAction(
    'FORM_ENGAGEMENT_REPORT_CHART_DATA_FAILED'
);

function fetchSchema() {
    return Schema.fetchForForms();
}

function fetchConnectedFormsSchema() {
    return Schema.connectedFormsFetch();
}

const requestFormsSchema = (): FormsSchemaRequestedAction => ({
    type: 'FORMS_SCHEMA_REQUESTED',
});

const updateFormsSchema = (schemaResponse: ListSchemaResponse): FormsSchemaUpdatedAction => ({
    type: 'FORMS_SCHEMA_UPDATED',
    payload: schemaResponse,
});

const requestConnectedFormsSchema = (): ConnectedFormsSchemaRequestedAction => ({
    type: 'CONNECTED_FORMS_SCHEMA_REQUESTED',
});

const updateConnectedFormsSchema = (
    schemaResponse: ListSchemaResponse
): ConnectedFormsSchemaUpdatedAction => ({
    type: 'CONNECTED_FORMS_SCHEMA_UPDATED',
    payload: schemaResponse,
});

/**
 * Fetches forms schema and fires corresponding actions to update our state
 *
 * @return {Function}            - Function to make API request and update state
 */
export function fetchSchemaAction() {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestFormsSchema());

        return fetchSchema().then((json) => {
            dispatch(updateFormsSchema(json));
        });
    };
}

export function fetchConnectedFormsSchemaAction() {
    return function(dispatch: Dispatch<*>) {
        dispatch(requestConnectedFormsSchema());

        return fetchConnectedFormsSchema().then((json) => {
            dispatch(updateConnectedFormsSchema(json));
        });
    };
}
