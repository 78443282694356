/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './bulleted-list.css';

type Bullet = React.Node | string;

type Props = {|
    bullets: Bullet[],
    size: 'normal' | 'big' | 'small',
    greyBullets?: boolean,
|};

export class BulletedList extends React.PureComponent<Props> {
    static defaultProps = {
        size: 'normal',
    };

    render() {
        const listStyle = classnames({
            'bulleted-list--small': this.props.size === 'small',
            'bulleted-list--normal': this.props.size === 'normal',
            'bulleted-list--big': this.props.size === 'big',
        });

        const itemStyle = classnames({
            'list-item--small': this.props.size === 'small',
            'list-item--normal': this.props.size === 'normal',
            'list-item--big': this.props.size === 'big',
            'list-item--grey': this.props.greyBullets,
        });

        return (
            <ul styleName={listStyle}>
                {this.props.bullets.map((bullet, index) => {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <li styleName={itemStyle} key={index}>
                            {bullet}
                        </li>
                    );
                })}
            </ul>
        );
    }
}
