/* @flow */

import * as React from 'react';

const DEFAULT_HEIGHT = 25;

export type PillProps = {
    height?: number,
    width?: number,
};

export function pillWrapper(Component: React.ComponentType<*>) {
    const Pill = (props: PillProps) => {
        const height = !props.height && !props.width ? DEFAULT_HEIGHT : props.height;

        return <Component height={height} width={props.width} />;
    };

    return Pill;
}
