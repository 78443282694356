/* @flow */

import * as React from 'react';

import {Button} from 'shells/button';
import {CogIcon} from 'shells/icon';

import './customize-button.css';

type Props = {
    onClick: () => void,
};

export function CustomizeButton(props: Props) {
    return (
        <div id='for-intercom-customize-button' styleName='action-button'>
            <Button icon={CogIcon} onClick={props.onClick} size='big'>
                Customize fields
            </Button>
        </div>
    );
}
