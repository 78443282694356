/* @flow */

import * as React from 'react';

import {ButtonCard} from './button-card';
import type {ControlOption} from './segmented-control';

import './segmented-control.css';

type Props = {
    option: ControlOption,
    isSelected: boolean,
    setSelectedValue: (value: any) => void,
    isDisabled?: boolean,
    onChange?: (value: any) => void,
};

export function SegmentedControlButton({option, isSelected, setSelectedValue, ...props}: Props) {
    let titleClass = 'title';
    if (option.type === 'large') {
        titleClass += ' title--large';
    } else if (isSelected) {
        titleClass += ' title--selected';
    }

    const renderContent = () => {
        if (option.type === 'small') {
            return (
                <div styleName='button-content--small'>
                    <div styleName={titleClass}>{option.title}</div>
                </div>
            );
        } else {
            return (
                <div styleName='button-content--large'>
                    <div styleName={titleClass}>{option.title}</div>
                    <div styleName='inner-text'>{option.subject}</div>
                    <div className='truncate' styleName='inner-text'>
                        {option.content}
                    </div>
                </div>
            );
        }
    };

    return (
        <ButtonCard
            isSelected={isSelected}
            onClick={() => {
                setSelectedValue(option.value);
                props.onChange && props.onChange(option.value);
            }}
            isTransparent={!isSelected}
            isFlex={true}
            isDisabled={props.isDisabled}
        >
            {renderContent()}
        </ButtonCard>
    );
}
