/* @flow */

import * as React from 'react';

import {Card} from '../card';
import {colors, type ColorName} from '../colors';

type Props = {|
    cardHeight?: number,
    cardWidth?: number,
    numCards: number,
    cardBackgroundColor?: ColorName,
|};

export function GridListLoadingCards(props: Props) {
    const {cardHeight = 175, cardWidth, numCards, cardBackgroundColor = 'offWhite'} = props;

    return (
        <>
            {[...Array(numCards)].map((_, index) => (
                <Card
                    // eslint-disable-next-line react/no-array-index-key
                    key={`placeholder-${index}`}
                    hasBorder={true}
                    fitHeight={true}
                    backgroundColor={cardBackgroundColor}
                    borderColor={colors.offWhite}
                    hasShadow={false}
                >
                    <div style={{height: cardHeight, width: cardWidth}} />
                </Card>
            ))}
        </>
    );
}
