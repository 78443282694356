/* @flow */

import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import type {ListSchemaResponse} from 'nutshell-core/schema/schema-types';
import type {ReportRowData, TimeseriesReportData, ListItem} from 'nutshell-core/types';

import type {FormsAction, ConnectedFormsAction} from './forms-types';

import * as actions from './forms-actions';

type SchemaState = ?ListSchemaResponse;

type FormSubmissionsListState = {
    isLoading: boolean,
    isFirstLoad: boolean,
    errorMessage?: string,
    listItems: Object[],
    meta: Object,
};

const formSubmissionsListDefaultState = {
    isLoading: true,
    isFirstLoad: true,
    errorMessage: undefined,
    listItems: [],
    meta: {},
};

const isFilterDrawerOpen = createReducer(
    {
        [actions.toggleShowFilterDrawer]: (state) => !state,
    },
    false
);

export const formSubmissionsListReducer = createReducer(
    {
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestFormSubmissionsListData]: (state) => {
            return {...state, isLoading: true, errorMessage: undefined};
        },
        [actions.updateFormSubmissionsListData]: (state, {meta, listItems}) => {
            return {
                ...state,
                isLoading: false,
                isFirstLoad: false,
                meta: meta,
                listItems: listItems
                    ? listItems.map((item: ListItem) => {
                          return {
                              ...item,
                              id: item.links ? item.links.entity.id : item.id,
                          };
                      })
                    : undefined,
                errorMessage: undefined,
            };
        },
        [actions.failFormSubmissionsListData]: (state, err) => {
            return {...state, isLoading: false, isFirstLoad: false, errorMessage: err};
        },
    },
    formSubmissionsListDefaultState
);

type FormEngagementReportTableData = {
    rows: ?(ReportRowData[]),
    totals: ?ReportRowData,
};
type FormEngagementReportState = {
    timeseriesChart: {
        isLoading: boolean,
        isTimedOut: boolean,
        isErrored: boolean,
        chartData: ?TimeseriesReportData,
        prefix: string,
    },
};
const formEngagementReportDefaultState = {
    timeseriesChart: {
        isLoading: true,
        isTimedOut: false,
        isErrored: false,
        chartData: null,
        prefix: '$',
    },
};

const formEngagementReportReducer = createReducer(
    {
        [actions.updateFormEngagementReportTableData]: (
            state,
            payload: FormEngagementReportTableData
        ) => {
            return {...state, tableData: payload};
        },
        // $FlowIgnore - this works correctly from redux-act
        [actions.requestFormEngagementReportData]: (state) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: true,
                isTimedOut: false,
                isErrored: false,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.updateFormEngagementReportChartData]: (state, {chartData}) => {
            const newChartState = {
                ...state.timeseriesChart,
                isLoading: false,
                isTimedOut: false,
                isErrored: false,
                chartData,
            };

            return {...state, timeseriesChart: newChartState};
        },
        [actions.failFormEngagementReportChartData]: (state, err) => {
            if (err) {
                if (err.status === 504 || err.status === 502) {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: true,
                        isErrored: false,
                    };

                    return {...state, timeseriesChart: newChartState};
                } else {
                    const newChartState = {
                        ...state.timeseriesChart,
                        chartData: null,
                        isLoading: false,
                        isTimedOut: false,
                        isErrored: true,
                    };

                    return {...state, timeseriesChart: newChartState};
                }
            }

            return state;
        },
    },
    formEngagementReportDefaultState
);

const schemaReducer = (state: SchemaState = null, action: FormsAction): SchemaState => {
    switch (action.type) {
        case 'FORMS_SCHEMA_UPDATED':
            return action.payload;
        default:
            return state;
    }
};

const connectedFormSchemaReducer = (
    state: SchemaState = null,
    action: ConnectedFormsAction
): SchemaState => {
    switch (action.type) {
        case 'CONNECTED_FORMS_SCHEMA_UPDATED':
            return action.payload;
        default:
            return state;
    }
};

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const webFormsReducer = combineReducers({
    formSubmissionsList: formSubmissionsListReducer,
    formEngagementReport: formEngagementReportReducer,
    formSchema: schemaReducer,
    connectedFormSchema: connectedFormSchemaReducer,
    isFilterDrawerOpen,
});

export type WebFormsState = {
    formSubmissionsList: FormSubmissionsListState,
    formEngagementReport: FormEngagementReportState,
    formSchema: SchemaState,
    connectedFormSchema: SchemaState,
    isFilterDrawerOpen: boolean,
};
