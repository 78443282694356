/* @flow */
'no babel-plugin-flow-react-proptypes';

import * as React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {ButtonIcon} from '../icon';

import './button.css';

import type {ButtonVariant, ButtonSize} from './types';

// Separate style props because Button wrapper components may not have children.
export type ButtonStyleProps = {|
    variant?: ButtonVariant,
    size?: ButtonSize,
    isFullWidth?: boolean,
    wiggle?: boolean,
    getButtonRef?: any,
    icon?: React.ComponentType<*>,
    noPadding?: boolean, // sometimes you just want a button with NO padding
    tooltipText?: string,
    fitContent?: boolean,
    preventUnderline?: boolean,
|}; // Exact type because cannot contain children

type Props = {
    // TODO: Make this work, maybe needs newer than flow 0.53?
    // ...HTMLButtonElement,
    children: React.Node,
    ...ButtonStyleProps,
};

/**
This is the "base" Nutshell button component, which gives us a consistent way of rendering
a button element in our app. We have 5 main "variants" of this button, which just change its colors.

There are also 4 "sizes", which determine the width/height/padding and text size of the button.

It is up to the component using the Button to provide the text contents via `children`.
 */
export function Button(props: Props) {
    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    });

    const {
        variant = 'secondary',
        size = 'normal',
        isFullWidth,
        wiggle = false,
        noPadding,
        children,
        getButtonRef,
        icon,
        tooltipText,
        fitContent,
        preventUnderline,
        ...htmlButtonProps
    } = props;

    const styleName = classnames(
        variant,
        size,
        {'full-width': isFullWidth},
        {'no-padding': noPadding},
        {'fit-content': fitContent},
        {'prevent-underline': preventUnderline},
        {wiggle}
    );

    return (
        <button
            styleName={styleName}
            type='button'
            ref={getButtonRef}
            data-tip={tooltipText}
            data-type={variant === ('text-primary' || 'text-secondary') ? 'link' : undefined}
            {...htmlButtonProps}
        >
            {icon ? <ButtonIcon size={size} variant={variant} icon={icon} /> : undefined}
            {children}
        </button>
    );
}
