/* @flow */
import * as React from 'react';

import {InfoIcon} from '../icon';

import './help-styles.css';

type Props = {
    onClick: (e: SyntheticMouseEvent<>) => void,
    isError?: boolean,
    size?: number,
};

export const HelpButton = React.forwardRef<Props, HTMLButtonElement>((props: Props, ref) => {
    return (
        <button
            styleName={props.isError ? 'help-icon--error' : 'help-icon'}
            type='button'
            onClick={props.onClick}
            ref={ref}
        >
            <InfoIcon
                wrapWithDiv={false}
                size={props.size ? props.size : 16}
                useCurrentColor={true}
            />
        </button>
    );
});
