/* @flow */

import * as React from 'react';
import {FocusTrapZone} from '@fluentui/react/lib/FocusTrapZone';
import classnames from 'classnames';
import {Button, CloseButton, MinimizeButton} from '../button';
import {ChevronLeftIcon} from '../icon';

import styles from './modal-component.css';

type Props = {
    children?: any,
    className?: string,
    closeButton?: boolean,
    getBodyRef?: (?HTMLElement) => void,
    getCloseButtonRef?: (?HTMLButtonElement) => void,
    headerText?: string,
    headerSubtext?: string,
    headerBackButton?: boolean,
    onHeaderBackButtonPress?: () => any,
    actionButton?: React.Node,
    isClosing: boolean,
    isLeftAligned?: boolean,
    isWide?: boolean,
    noPadding?: boolean,
    onClose: () => void,
    onMinimize?: () => void,
    onOverlayClick: () => any,
    shouldTrapFocus?: boolean,
    maxHeight?: number,
    minHeight?: number,
    maxWidth?: number,
    minWidth?: number,
};

/*
 * Presentational component for our app modals. All styling
 * and actual UI rendering is done from this component.
 */
export class ModalComponent extends React.Component<Props> {
    render() {
        const styleName = this.props.isClosing ? 'container--animate-out' : 'container';

        return (
            <div styleName={styleName}>
                {this.props.shouldTrapFocus ? (
                    <FocusTrapZone
                        className={styles['focus-trap-zone']}
                        isClickableOutsideFocusTrap={true}
                    >
                        {this.renderModalContents()}
                    </FocusTrapZone>
                ) : (
                    this.renderModalContents()
                )}

                <div onClick={this.props.onOverlayClick} styleName='overlay' />
            </div>
        );
    }

    renderModalContents = () => {
        const bodyClassnames = classnames({
            body: !this.props.isWide,
            'body--wide': this.props.isWide,
            'body--no-padding': this.props.noPadding,
            'body--left-aligned': this.props.isLeftAligned,
        });

        return (
            <div
                className={this.props.className}
                ref={this.props.getBodyRef}
                styleName={bodyClassnames}
                style={{
                    maxHeight: this.props.maxHeight ? this.props.maxHeight : undefined,
                    minHeight: this.props.minHeight ? this.props.minHeight : undefined,
                    maxWidth: this.props.maxWidth ? this.props.maxWidth : undefined,
                    minWidth: this.props.minWidth ? this.props.minWidth : undefined,
                }}
            >
                {this.renderTopSection()}
                <div styleName='main-section'>{this.props.children}</div>
            </div>
        );
    };

    renderTopSection = () => {
        const {headerText, headerSubtext} = this.props;
        if (headerText) {
            return (
                <div styleName='header-bar'>
                    {this.renderHeaderBackButton()}
                    <div styleName='header-text'>
                        <div>{headerText}</div>
                        {headerSubtext ? <div>{headerSubtext}</div> : null}
                    </div>
                    <div className='flex'>
                        {this.props.actionButton}
                        {this.renderCloseButtons()}
                    </div>
                </div>
            );
        }

        // If we don't have a header, but we do want a close button, we need to
        // absolutely position _just_ a close button on the right side.
        if (this.props.closeButton) {
            return this.renderCloseButtons();
        }

        return null;
    };

    renderCloseButtons = () => {
        return (
            <div styleName='close-button-container'>
                {this.props.onMinimize ? (
                    <MinimizeButton onClick={this.props.onMinimize} size={17} />
                ) : (
                    undefined
                )}
                {this.props.closeButton ? (
                    <CloseButton
                        getRef={this.props.getCloseButtonRef}
                        tooltip='Close'
                        onClick={this.props.onClose}
                        size={17}
                    />
                ) : (
                    undefined
                )}
            </div>
        );
    };

    renderHeaderBackButton = () => {
        if (this.props.headerBackButton && this.props.onHeaderBackButtonPress) {
            return (
                <Button
                    size='big'
                    icon={ChevronLeftIcon}
                    onClick={this.props.onHeaderBackButtonPress}
                >
                    Back
                </Button>
            );
        }

        return undefined;
    };
}
