/* @flow */

import * as React from 'react';
import {useQuery} from '@apollo/react-hooks';
import {getPrimaryEmailAddress} from 'nutshell-core/utils/peep/get-primary-email-address';

import type {
    GetSubmission as GetSubmissionQuery,
    GetSubmissionVariables as GetSubmissionQueryVariables,
} from 'nutshell-graphql-types';

import {LoadingPage} from 'shells/loading-page';
import {ViewSubmissionData} from 'shells/form/view-submission-modal-content/view-submission-data';

import {getValueForField} from '../../peep/form-submissions/form-submissions-section';
import GET_SUBMISSION from '../../forms/timeline/view-form-submission/graphql/queries/get-submission.graphql';

import './submission-data.css';

type Props = {
    submissionId: string,
};

export function SubmissionData(props: Props) {
    const {data, loading} = useQuery<GetSubmissionQuery, GetSubmissionQueryVariables>(
        GET_SUBMISSION,
        {
            variables: {id: props.submissionId},
            notifyOnNetworkStatusChange: true,
        }
    );

    const submission = data ? data.submission : null;

    let submittedFields = [];
    if (submission) {
        submittedFields = submission.fields.edges.map((edge) => ({
            id: edge.node.id,
            label: edge.node.label,
            isRequired: edge.node.isRequired,
            value: getValueForField(edge.fieldValue),
        }));

        if (
            submission.booking &&
            submission.booking.additionalAttendees &&
            submission.booking.additionalAttendees.length
        ) {
            submittedFields.push({
                id: 'additionalAttendees',
                label: 'Additional Attendees',
                value: submission.booking.additionalAttendees
                    .map((attendee) => attendee && getPrimaryEmailAddress(attendee.emailAddresses))
                    .join(', '),
                isRequired: false,
            });
        }
    }

    return (
        <>
            {loading ? <LoadingPage /> : undefined}
            <ViewSubmissionData submittedFields={submittedFields} />
        </>
    );
}
