/* @flow */

import * as React from 'react';

import {Title, Body} from '../typography';

import './empty-state-small.css';

type Props = {
    icon?: any,
    title: string,
    subtitle: React.Node | string,
    cta?: React.Node,
};

export class EmptyStateSmall extends React.PureComponent<Props> {
    render() {
        return (
            <div styleName='container'>
                <div styleName='content-container'>
                    {this.props.icon}
                    <div styleName='empty-text'>
                        <Title>{this.props.title}</Title>
                        <Body>{this.props.subtitle}</Body>
                    </div>
                </div>
                {this.props.cta}
            </div>
        );
    }
}
