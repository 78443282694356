import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import {NutshellPro} from '../../illustrations';

function SvgComponent() {
    return <NutshellPro width={100} />;
}
SvgComponent.iconName = 'nutshellPro';

export const NutshellProIcon = svgIconWrapper(SvgComponent);
