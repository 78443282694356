/* @flow */
import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

import type {
    ComparedActivityReportSummary,
    ReportRowData,
    ReportsResponse,
} from 'nutshell-core/types';
import {api} from 'nutshell-core/api';
import {fetchList} from 'nutshell-core/entities/fetch-list';
import {
    jsonToTimeseriesReportData,
    jsonToReportTableData,
    jsonToReportTableTotalsData,
    jsonToForecastSummaryData,
    addReferenceData,
} from 'nutshell-core/json-to-report-data';
import {convertToObj, convertToArray} from 'nutshell-core/filter';
import {getComparisonPeriodFilters} from 'nutshell-core/get-comparison-dates-from-range';
import {safelyParseError} from 'nutshell-core/utils';

import {mapTableDataToChannelBuckets} from './map-table-data-to-channel-buckets';
import {FORECAST, ACTIVITY, EMAIL} from './report-constants';
import type {ChartReportParams, LeadsReportKey} from './report-types';
import {getPostParams, type ReportPostParams} from './report-utils';
import {
    updateReportMaps,
    updateForecastSummaryStats,
    updateActivitySummaryStats,
    updateEmailSummaryStats,
    updateLeadsReportChartData,
    updateLeadsReportTableData,
    failLeadsReportChartData,
    updateReportLeadsListData,
    updateReportLeadsListSummaryData,
    failReportLeadsListData,
    type LeadsReportDataRequestedAction,
    type LeadsReportListRequestedAction,
} from './reports-actions';

import {
    failActivityReportSummary,
    resolveActivityReportSummary,
    type ActivityReportSummaryRequestAction,
} from './activity/activity-report-actions';

import {
    failEmailReportSummary,
    resolveEmailReportSummary,
    type EmailReportSummaryRequestAction,
} from './activity/email-report-actions';

const REST_URL = '/rest/leads/report';
const REST_ACTIVITY_URL = '/rest/activities/report';
const REST_EMAIL_URL = '/rest/emails/report';

const DATE_RANGE_TYPE = 'period';

export const requestReportLeadsListEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('REPORT_LEADS_LIST_DATA_REQUESTED')
        .switchMap((action: LeadsReportListRequestedAction) => {
            const {filters, pageNum = 1, columns, sort, reportKey} = action.payload;

            // Our pagination components aren't 0-based, but this specific
            // request expects page 0 to be the first page. Using Math.max() here
            // in case older URLs with page 0 are still in the wild
            const adjustedPageNum = Math.max(0, pageNum - 1);

            const listRequestStream = Observable.fromPromise(
                fetchList('leads', {
                    filter: filters,
                    sort,
                    page: {page: adjustedPageNum},
                    fields: columns,
                })
            );

            const leadListDataStream = listRequestStream.map((response) =>
                updateReportLeadsListData(response)
            );
            const leadListSummartDataStream = listRequestStream
                .filter(() => reportKey !== FORECAST)
                .map((response) => updateReportLeadsListSummaryData(response));

            return leadListDataStream.merge(leadListSummartDataStream).catch((err) => {
                const safeError = safelyParseError(err);

                return Observable.of(failReportLeadsListData(safeError));
            });
        });

export const requestLeadsReportDataEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('REPORTS_LEADS_REPORT_DATA_REQUESTED')
        .switchMap((action: LeadsReportDataRequestedAction) => {
            const {reportKey, reportParams, filters} = action.payload;

            const params = getPostParams(reportKey, reportParams, filters);
            const comparisonParams = getComparisonParams(reportKey, reportParams, filters);

            const requestStream = Observable.fromPromise(fetchData(params, comparisonParams));
            const currentResultStream = requestStream.map((res) => res[0]); // We don't display comparison data in the table for now
            const reportDataStream = requestStream.map(transformDataForReport);

            const reportMapStream = currentResultStream
                .filter(
                    (currentResult) =>
                        currentResult && currentResult.reports && currentResult.reports.length
                )
                .map((currentResult) => {
                    const newReportMaps = currentResult.reports.reduce((reportAccum, report) => {
                        reportAccum[report.id] = {
                            id: report.id,
                            name: report.name,
                            key: report.key,
                            prefix: report.prefix,
                        };

                        return reportAccum;
                    }, {});

                    return updateReportMaps(newReportMaps);
                });

            const forecastSummaryStatStream = currentResultStream
                .filter(() => reportKey === FORECAST)
                .map((currentResult) =>
                    updateForecastSummaryStats(jsonToForecastSummaryData(currentResult))
                );

            const activitySummaryStatStream = currentResultStream
                .filter(() => reportKey === ACTIVITY)
                .map((currentResult) => updateActivitySummaryStats(currentResult.meta));

            const emailSummaryStatStream = currentResultStream
                .filter(() => reportKey === EMAIL)
                .map((currentResult) => updateEmailSummaryStats(currentResult.meta));

            const reportChartDataStream = reportDataStream
                .map((data) => data.chartData)
                .filter((chartData) => Boolean(chartData))
                .map((chartData) => updateLeadsReportChartData({chartData}));

            const reportTableDataStream = reportDataStream.map(({tableData}) => {
                if (reportKey === 'attribution') {
                    const data = mapTableDataToChannelBuckets(tableData);

                    return updateLeadsReportTableData(data);
                }

                return updateLeadsReportTableData(tableData);
            });

            return reportMapStream
                .merge(
                    forecastSummaryStatStream.merge(
                        activitySummaryStatStream.merge(
                            emailSummaryStatStream.merge(
                                reportChartDataStream.merge(reportTableDataStream)
                            )
                        )
                    )
                )
                .catch((err) => Observable.of(failLeadsReportChartData(err)));
        });

export const requestActivityReportSummaryEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('ACTIVITY_REPORT_FETCH_SUMMARY_REQUEST')
        .switchMap((action: ActivityReportSummaryRequestAction) => {
            const {filters, comparisonType} = action.payload;

            let previousPeriodFilters = null;
            if (comparisonType) {
                previousPeriodFilters = getComparisonPeriodFilters(
                    DATE_RANGE_TYPE,
                    filters,
                    comparisonType
                );
                if (!previousPeriodFilters) {
                    return Observable.of(failActivityReportSummary());
                }
            }

            return Observable.fromPromise(
                Promise.all([
                    fetchActivityReportSummaryData(filters),
                    previousPeriodFilters
                        ? fetchActivityReportSummaryData(previousPeriodFilters)
                        : Promise.resolve(null),
                    // $FlowFixMe upgrading Flow to v0.92.1 on web
                ]).then((combinedResponses: [ReportsResponse, ?ReportsResponse]) => {
                    const data: ComparedActivityReportSummary<
                        Array<ReportRowData>
                    > = combineTwoReports(combinedResponses, jsonToReportTableData);
                    const totals: ComparedActivityReportSummary<ReportRowData> = combineTwoReports(
                        combinedResponses,
                        jsonToReportTableTotalsData
                    );

                    return resolveActivityReportSummary({data, totals});
                    // TODO (@ianvs): handle the case of no reports
                })
            ).catch((err) => {
                return Observable.of(failActivityReportSummary(combineTwoReports(err)));
            });
        });

export const requestEmailReportSummaryEpic = (action$: ActionsObservable<*>) =>
    action$
        .ofType('EMAIL_REPORT_FETCH_SUMMARY_REQUEST')
        .switchMap((action: EmailReportSummaryRequestAction) => {
            const {filters, comparisonType} = action.payload;

            let previousPeriodFilters = null;
            if (comparisonType) {
                previousPeriodFilters = getComparisonPeriodFilters(
                    DATE_RANGE_TYPE,
                    filters,
                    comparisonType
                );
                if (!previousPeriodFilters) {
                    return Observable.of(failEmailReportSummary());
                }
            }

            return Observable.fromPromise(
                Promise.all([
                    fetchEmailReportSummaryData(filters),
                    previousPeriodFilters
                        ? fetchEmailReportSummaryData(previousPeriodFilters)
                        : Promise.resolve(null),
                    // $FlowFixMe upgrading Flow to v0.92.1 on web
                ]).then((combinedResponses: [ReportsResponse, ?ReportsResponse]) => {
                    const data: ComparedActivityReportSummary<
                        Array<ReportRowData>
                    > = combineTwoReports(combinedResponses, jsonToReportTableData);
                    const totals: ComparedActivityReportSummary<ReportRowData> = combineTwoReports(
                        combinedResponses,
                        jsonToReportTableTotalsData
                    );

                    return resolveEmailReportSummary({data, totals});
                })
            ).catch((err) => {
                return Observable.of(failEmailReportSummary(combineTwoReports(err)));
            });
        });

function fetchData(params: ReportPostParams, comparisonParams: ?ReportPostParams): Promise<any> {
    const reportDataRequest = comparisonParams
        ? Promise.all([fetchReportData(params), fetchReportData(comparisonParams)])
        : Promise.all([fetchReportData(params)]);

    return reportDataRequest;
}

function fetchReportData(params) {
    let url = REST_URL;
    if (params.reportType === 'activity') {
        url = REST_ACTIVITY_URL;
    } else if (params.reportType === 'email') {
        url = REST_EMAIL_URL;
    }

    const ajaxParams = {
        url,
        dataType: 'json',
        data: params,
    };

    return ($.ajax(ajaxParams): any); // This is how we have to convince flow that the response is a ReportsResponse
}

function transformDataForReport(results: Array<ReportsResponse>) {
    let chartData;
    const currentResult = results[0];
    if (!currentResult || !Array.isArray(currentResult.reports))
        return {chartData: null, tableData: null};
    const comparisonResult = results.length > 1 && results[1];

    const currentChartData = jsonToTimeseriesReportData(currentResult);
    if (comparisonResult) {
        const referenceData = jsonToTimeseriesReportData(comparisonResult);
        chartData = addReferenceData(currentChartData, referenceData);
    } else {
        chartData = currentChartData;
    }

    const rows = jsonToReportTableData(currentResult);
    const totals = jsonToReportTableTotalsData(currentResult);
    const tableData = {rows, totals};

    return {chartData, tableData};
}

function getComparisonParams(
    reportKey: LeadsReportKey,
    reportParams: ChartReportParams,
    filters: Array<Object>
) {
    if (reportParams.compareTo && filters) {
        const compareTo = reportParams.compareTo;
        const comparisonFilters = convertToArray(
            getComparisonPeriodFilters(reportParams.dateRangeType, convertToObj(filters), compareTo)
        );
        // TODO (@ianvs): figure out what to do with this
        // const comparisonExportLink = `${REST_URL}?${Routing.param(getPostParams(reportKey, dateRangeType, newReportParams, 'csv'))}`;

        return getPostParams(reportKey, reportParams, comparisonFilters);
    }
}

/**
 * Helper to just fetch a single report of activity summary data, using some filters
 *
 * @param  {Object} filters - Filter objects to use for the fetch
 * @return {Promise}        - Fetch promise
 */
function fetchActivityReportSummaryData(filters: Object) {
    const params = {
        reportType: 'quota', // Summary data
        groupByField: 'user',
        segmentByField: 'activityType',
        filter: filters,
    };

    return api.get('activities/report', params).then((res) => res.json());
}

/**
 * Helper to just fetch a single report of activity summary data, using some filters
 *
 * @param  {Object} filters - Filter objects to use for the fetch
 * @return {Promise}        - Fetch promise
 */
function fetchEmailReportSummaryData(filters: Object) {
    const params = {
        reportType: 'quota', // Summary data
        groupByField: 'user',
        segmentByField: 'emailSequenceTemplateGroups',
        filter: filters,
    };

    return api.get('emails/report', params).then((res) => res.json());
}

/**
 * Helper function to combine two reports repsonses into a single object.
 *
 * Right now, this just maps both of their data and returns an object with
 * two keys - this can be dramatically improved if we feel this is insufficient.
 * @param  {ReportsResponse[]} res     Array of reports responses, length 2
 * @param  {function} [processor]      Function to apply against each of the responses, defaults to a no-op
 * @return {Object}                    Object with two keys, one for each report
 */
function combineTwoReports(
    [currentPeriod, compareTo]: [ReportsResponse, ?ReportsResponse],
    processor?: (ReportsResponse) => any
): Object {
    const safeProcessor = processor ? processor : (x) => x;

    return {
        currentPeriod: safeProcessor(currentPeriod),
        compareTo: compareTo ? safeProcessor(compareTo) : null,
    };
}
