/* @flow */

import * as React from 'react';

import {SegmentedControlButton} from './segmented-control-button';

import './segmented-control.css';

export type ControlOption = ControlOptionSmall | ControlOptionLarge;

type ControlOptionSmall = {
    type: 'small',
    title: string,
    value: any,
};

type ControlOptionLarge = {
    type: 'large',
    title: string,
    subject: string,
    content: ?string,
    value: any,
};

type Props = {
    buttonOptions: Array<ControlOption>,
    selectedValue: any,
    setSelectedValue: (value: any) => void,
    isDisabled?: boolean,
    onChange?: (value: any) => void,
};

export function SegmentedControl(props: Props) {
    return (
        <div styleName='control-container'>
            {props.buttonOptions.map((option) => (
                <SegmentedControlButton
                    {...props}
                    key={option.title}
                    option={option}
                    isSelected={option.value === props.selectedValue}
                />
            ))}
        </div>
    );
}
