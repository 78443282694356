/* @flow */

import {unexpectedCase} from 'nutshell-core/reducer-utils';
import {REHYDRATE} from 'redux-persist';
import {ActionTypes, type ConnectedFormsAction} from './connected-forms-actions';

export type ConnectedFormsState = {
    activeFieldId: ?string,
};

export const defaultConnectedFormsState = {
    activeFieldId: null,
};

export const connectedFormsReducer = (
    state: ConnectedFormsState = defaultConnectedFormsState,
    action: ConnectedFormsAction
): ConnectedFormsState => {
    switch (action.type) {
        case ActionTypes.ACTIVE_FIELD_ID_SET:
            return {
                ...state,
                activeFieldId: action.payload.activeFieldId,
            };
        case REHYDRATE: {
            const persistedState = action.payload;
            if (!persistedState || !persistedState.ui || !persistedState.ui.connectedForms) {
                return state;
            }

            return persistedState.ui.connectedForms;
        }
        default:
            unexpectedCase(action.type);

            return state;
    }
};
