/* @flow */
/* eslint-disable spaced-comment */

import * as React from 'react';
import {ChevronLeftIcon} from '../../icon';
import './back-button.css';

// Need to use wonky comment type because of https://github.com/storybookjs/storybook/issues/8017

/*flow-include
type Props<T> = {|
    ...$Exact<React.ElementConfig<T>>,
    backText: string,
    as: T,
|};
*/
export class BackButton<T: string | React.ComponentType<*>> extends React.PureComponent<Props<T>> {
    static defaultProps = {
        backText: 'Back',
        as: 'button',
    };

    render() {
        const {as: Component, backText, ...other} = this.props;
        const htmlProps = Component === 'button' ? {type: 'button', ...other} : other;

        return (
            <Component {...htmlProps} styleName='container'>
                <ChevronLeftIcon /> &nbsp;{backText}
            </Component>
        );
    }
}
