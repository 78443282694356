/* @flow */

import * as React from 'react';

import {ModalLightbox} from 'shells/modal';
import {CloseButton} from 'shells/button';
import {LoadingPage} from 'shells/loading-page';

import type {TimelineEntryIconVariant} from 'shells/timeline/gutter/types';
import type {TimelineHeaderProps} from 'shells/timeline/entry/header';
import {TimelineModalHeader} from 'shells/timeline/entry/modals/timeline-modal-header';

import './timeline-modal-wrapper.css';

type Props = {|
    isLoading?: boolean,
    onClose: () => void,
    iconVariant: TimelineEntryIconVariant,
    children: React.Node,
    // Header props
    ...TimelineHeaderProps,
|};

export function TimelineModalWrapper(props: Props) {
    const {
        isLoading,
        onClose,
        iconVariant,
        timestamp,
        actor,
        actorEmailAddress,
        participants,
        entity,
        entryType,
        changeType,
        numVisits,
        children,
    } = props;

    return (
        <ModalLightbox onClose={onClose} isOpen={true}>
            <div styleName='timeline-modal'>
                <CloseButton variant='light' size={16} onClick={onClose} />
                <div styleName='modal-content-container'>
                    {isLoading ? (
                        <div styleName='loading-container'>
                            <LoadingPage />
                        </div>
                    ) : (
                        <>
                            <TimelineModalHeader
                                iconVariant={iconVariant}
                                timestamp={timestamp}
                                actor={actor}
                                actorEmailAddress={actorEmailAddress}
                                participants={participants}
                                entity={entity}
                                entryType={entryType}
                                changeType={changeType}
                                numVisits={numVisits}
                            />
                            {children}
                        </>
                    )}
                </div>
            </div>
        </ModalLightbox>
    );
}
