/* @flow */

import React from 'react';

import {pillWrapper} from '../../acorn-pill/pill-wrapper';
import NutshellFoundation from './icon-nutshell-foundation.svg';

type Props = {
    width?: number,
    height?: number,
};

function SvgComponent(props: Props) {
    return <NutshellFoundation width={props.width} height={props.height} />;
}
SvgComponent.iconName = 'nutshellFoundation';

export const NutshellFoundationIcon = pillWrapper(SvgComponent);
