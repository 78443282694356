/* @flow */
'no babel-plugin-flow-react-proptypes';

import {applyMiddleware, compose} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {createEpicMiddleware} from 'redux-observable';
import thunk from 'redux-thunk';
import immutableStateInvariant from 'redux-immutable-state-invariant';
import {persistStore, persistReducer, persistCombineReducers, createTransform} from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage'; // or whatever storage you are using

import {Store, type NutshellSharedState} from 'nutshell-core/store';
import {Accounts} from 'nutshell-core/accounts';
import {Billing} from 'nutshell-core/billing';
import * as Contacts from 'nutshell-core/contacts';
import {Session, type SessionModuleState} from 'nutshell-core/session';
import {Tasks, type TasksModuleState} from 'nutshell-core/tasks';

import {App} from './app/index';
import {dashboardReducer} from './ui/master-dashboard/dashboard-reducer';
import {uiReducer, type UiState} from './ui/ui-reducer';
import {emailComposerFormReducerPlugin} from './ui/email/sender/email-composer-form-reducer-plugin';
import {reportsReducer, type ReportsState} from './ui/reports/reports-reducer';
import {
    audiencesReducer,
    type AudiencesState,
} from './ui/email-marketing/audiences/audiences-reducer';
import {editionsReducer, type EditionsState} from './ui/email-marketing/editions/editions-reducer';
import {webFormsReducer, type WebFormsState} from './ui/forms/forms-reducer';
import {
    siteAnalyticsReducer,
    type SiteAnalyticsState,
} from './ui/email-marketing/analytics/report/analytics-reducer';
import {
    mcfxCompaniesReducer,
    type McfxCompaniesState,
} from './ui/email-marketing/visitors/companies-list/companies-reducer';
import {
    listSelectionReducer,
    type PeopleListSelectionState,
} from './ui/email-marketing/entity-lists/list-selection-redux/list-selection-reducer';
import {
    SIGNUP_REDUX_FORM,
    LOGIN_REDUX_FORM,
    SET_NEW_PASSWORD_REDUX_FORM,
} from './ui/auth/constants';
import {EntityList, type EntityListState} from './entity-list';
import {rootEpic} from './root-epic';

import {listenToLegacyEvents} from './legacy-event-listeners';

const process = require('process'); // eslint-disable-line import/no-nodejs-modules, import/no-commonjs

type CollectionItemsState = {
    items: Object[],
};

// Work in progress Nutshell State object, contains much more than what is
// listed here
export type NutshellState = NutshellSharedState &
    App.AppModuleState &
    Accounts.AccountsModuleState &
    Billing.BillingModuleState &
    Contacts.ContactsModuleState &
    SessionModuleState &
    TasksModuleState & {|
        audiences: AudiencesState,
        editions: EditionsState,
        peopleListSelection: PeopleListSelectionState,
        entityList: EntityListState,
        reports: ReportsState,
        users: CollectionItemsState,
        teams: CollectionItemsState,
        stagesets: CollectionItemsState,
        ui: UiState,
        form: Object,
        webForms: WebFormsState,
        siteAnalytics: SiteAnalyticsState,
        mcfxCompanies: McfxCompaniesState,
    |};

const IS_TEST_ENV = process.env.NODE_ENV === 'test';

// List of reducer keys that we purposefully we _not_ rehydrate
// with redux-persist i.e. on refresh all state goes away
const PERSIST_BLACKLISTED_REDUCERS = [
    'app',
    'activityTypes',
    'emailSequenceTemplateGroups',
    'audiences',
    'peopleListSelection',
    'competitors',
    'competitors',
    'editions',
    'entities',
    'form',
    'webForms',
    'leads',
    'listItems',
    'markets',
    'mcfxCompanies',
    'origins',
    'outcomes',
    'products',
    'reports',
    'routing',
    'salesAutomation',
    'siteAnalytics',
    'sources',
    'tags',
    'teams',
    'territories',
    'users',
    'visualPipeline',
];

export function configureStore() {
    const reducer = {
        [App.REDUCER_KEY]: App.reducer,
        [Accounts.REDUCER_KEY]: Accounts.reducer,
        audiences: audiencesReducer,
        peopleListSelection: listSelectionReducer,
        [Billing.REDUCER_KEY]: Billing.reducer,
        [Contacts.REDUCER_KEY]: Contacts.reducer,
        dashboard: dashboardReducer,
        editions: editionsReducer,
        entityList: EntityList.reducer,
        form: getReduxFormReducer(),
        [Session.REDUCER_KEY]: Session.reducer,
        [Tasks.REDUCER_KEY]: Tasks.reducer,
        reports: reportsReducer,
        ui: uiReducer,
        webForms: webFormsReducer,
        siteAnalytics: siteAnalyticsReducer,
        mcfxCompanies: mcfxCompaniesReducer,
    };

    const epicMiddleware = createEpicMiddleware(rootEpic);
    const middleware = [thunk];
    if (typeof NutDebug !== 'undefined' && NutDebug.immutableStateInvariant) {
        middleware.unshift(immutableStateInvariant());
    }

    middleware.push(epicMiddleware);

    const enhancers = IS_TEST_ENV
        ? applyMiddleware(...middleware)
        : compose(applyMiddleware(...middleware), reduxDevTools());

    // We need to pass a callback function to our shared store,
    // since we don't want to use the standard `combineReducers`
    // from redux, but instead the `peristCombineReducers` function
    // from `redux-persist`, which also uses a config
    const combineFunction = (reducers) => {
        const persistConfig = {
            key: 'root',
            storage: storage,
            blacklist: PERSIST_BLACKLISTED_REDUCERS,
            // debug: true, // Uncomment this line for additional debugging!
        };

        return persistCombineReducers(persistConfig, reducers);
    };

    return Store.configure(combineFunction, reducer, enhancers);
}

export const store = configureStore();
export const persistor = persistStore(store);

// We need some baseline session data set up upon initialization of our redux
// application, so we just immediately dispatch the fetch action after our store
// has been configured.
// But if we aren't logged in don't bother getting session data
const body = document.querySelector('body');
if (body && body.hasAttribute('data-logged-in')) {
    store.dispatch(Session.requestFetchSession());
}

// Start listening to legacy events
listenToLegacyEvents(store);

/**
 * Execute the redux devtools chrome extension if it exists and is safe to do so
 * (i.e. on development server)
 *
 * @return {function}  Middleware for devtools or noop
 */
function reduxDevTools() {
    if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        return window.__REDUX_DEVTOOLS_EXTENSION__();
    }

    return (f) => f;
}

function getReduxFormReducer() {
    const preventSavingFormsWithPasswords = createTransform(
        () => {
            // Don't allow anything to be written to localstorage at all.
            // Mostly this is to prevent passwords from being stored in localstorage, but it also
            // serves to avoid rehydrating form data, which we don't want either.
            return {};
        },
        null,
        // define which reducers this transform gets called for.
        {whitelist: [SIGNUP_REDUX_FORM, LOGIN_REDUX_FORM, SET_NEW_PASSWORD_REDUX_FORM]}
    );

    const formPersistConfig = {
        key: 'form',
        storage: storage,
        stateReconciler: autoMergeLevel1,
        transforms: [preventSavingFormsWithPasswords],
        // debug: true, // Uncomment this line for additional debugging!
    };

    // Special formReducer plugin here to do some
    // manipulation to our email composer state
    // when redux-form CHANGE actions occur
    // $FlowFixMe upgrading Flow to v0.110.1
    const formReducerWithPlugins = formReducer.plugin({
        ...emailComposerFormReducerPlugin,
    });

    return persistReducer(formPersistConfig, formReducerWithPlugins);
}
