/* @flow */

import {
    DATE_RANGES_PAST,
    DATE_RANGES_FUTURE,
    LAST_365_DAYS,
    DATE_RANGES_MINUTES,
    DATE_RANGES_FORMS,
    DATE_RANGES_ANALYTICS,
    DATE_RANGES_ATTRIBUTION,
} from 'nutshell-core/constants';

import {
    LOSSES,
    LOSSES_SAVED_TYPE,
    NEW_LEADS,
    NEW_LEADS_SAVED_TYPE,
    SALES,
    SALES_SAVED_TYPE,
    FORECAST,
    FORECAST_SAVED_TYPE,
    FUNNEL,
    FUNNEL_SAVED_TYPE,
    CUSTOM,
    CUSTOM_SAVED_TYPE,
    EMAIL,
    EMAIL_SAVED_TYPE,
    ACTIVITY,
    ACTIVITY_SAVED_TYPE,
    AUDIENCE_MEMBERSHIP,
    EDITION_ENGAGEMENT,
    DRIP_EDITION_ENGAGEMENT,
    FORM_ENGAGEMENT,
    LEAD_LIST_EXPORT_KEY,
    REPORT_DATA_EXPORT_KEY,
    REPORT_CHART_EXPORT_KEY,
    REPORT_ACTIVITY_DETAILS_EXPORT_KEY,
    REPORT_ACTIVITY_OVERVIEW_EXPORT_KEY,
    SITE_ANALYTICS,
    ATTRIBUTION,
    ATTRIBUTION_SAVED_TYPE,
    MCFXCOMPANIES,
    SCHEDULER,
    COMPETITORS,
} from './report-types';

import type {
    ReportKey,
    LeadsReportKey,
    FunnelReportKey,
    ReportSavedTypeKey,
    ExportKey,
    ReportSegment,
} from './report-types';

import ActivityIcon from './report-icons/icon-report-activity.svg';
import EmailIcon from './report-icons/icon-report-email.svg';
import AttributionIcon from './report-icons/icon-report-attribution.svg';
import CustomIcon from './report-icons/icon-report-custom.svg';
import ForecastIcon from './report-icons/icon-report-forecast.svg';
import FunnelIcon from './report-icons/icon-report-funnel.svg';
import LossesIcon from './report-icons/icon-report-lost-deals.svg';
import NewLeadsIcon from './report-icons/icon-report-new-leads.svg';
import SalesIcon from './report-icons/icon-report-sales.svg';
import {getAttributionReportDescription} from './report-helpers';

export {
    LOSSES,
    NEW_LEADS,
    SALES,
    FORECAST,
    ATTRIBUTION,
    FUNNEL,
    CUSTOM,
    ACTIVITY,
    EMAIL,
    SITE_ANALYTICS,
    LEAD_LIST_EXPORT_KEY,
    REPORT_DATA_EXPORT_KEY,
    REPORT_CHART_EXPORT_KEY,
    REPORT_ACTIVITY_DETAILS_EXPORT_KEY,
    REPORT_ACTIVITY_OVERVIEW_EXPORT_KEY,
    AUDIENCE_MEMBERSHIP,
    EDITION_ENGAGEMENT,
    DRIP_EDITION_ENGAGEMENT,
    FORM_ENGAGEMENT,
    MCFXCOMPANIES,
    SCHEDULER,
};
export const NO_SEGMENT_ID = 'none';
export const SEGMENT_PRIORITY_FACET = 'count';

// We reference this ID in multiple places for onboarding,
// downloading a browser-generated image of this div, etc.
export const CHART_HERO_DIV_ID = 'chart-hero';

type SegmentOption = {
    id: ReportSegment | 'none',
    label: string,
};

// Segment-by options
export const SEGMENT_NONE = {id: NO_SEGMENT_ID, label: 'None'};
export const SEGMENT_SUBSCRIPTION_STATUS = {
    id: 'subscriptionStatus',
    label: 'Subscription status',
    shouldStack: {count: true, total: false},
};
export const SEGMENT_INTERACTION_TYPE = {
    id: 'interactionType',
    label: 'Interaction',
    shouldStack: {count: false, total: false},
};
export const SEGMENT_PRODUCT = {
    id: 'products',
    label: 'Product',
    shouldStack: {count: false, total: true},
};
export const SEGMENT_SOURCE = {
    id: 'sources',
    label: 'Source',
    shouldStack: {count: false, total: false},
};

export const SEGMENT_CHANNEL = {
    id: 'channels',
    label: 'Channel',
    shouldStack: {count: false, total: false},
};
export const SEGMENT_COMPETITOR = {
    id: 'competitors',
    label: 'Competitor',
    shouldStack: {count: false, total: false},
};
export const SEGMENT_ASSIGNEE = {
    id: 'assignees',
    label: 'Assignee',
    shouldStack: {count: true, total: true},
};
export const SEGMENT_USER = {id: 'user', label: 'User', shouldStack: {count: false, total: false}};
export const SEGMENT_STAGE = {
    id: 'stages',
    label: 'Stage',
    shouldStack: {count: true, total: true},
};
export const SEGMENT_STATUS = {
    id: 'status',
    label: 'Status',
    shouldStack: {count: true, total: true},
};
export const SEGMENT_TAG = {id: 'tags', label: 'Tag', shouldStack: {count: false, total: false}};
export const SEGMENT_OUTCOME = {
    id: 'outcomes',
    label: 'Outcome',
    shouldStack: {count: true, total: true},
};
export const SEGMENT_TERRITORY = {
    id: 'territories',
    label: 'Territory',
    shouldStack: {count: true, total: true},
};
export const SEGMENT_ACTIVITY_TYPE = {
    id: 'activityType',
    label: 'Type',
    shouldStack: {count: true, total: true},
};
export const SEGMENT_EMAIL_SEQUENCE_TEMPLATE_GROUP = {
    id: 'emailSequenceTemplateGroups',
    label: 'Template group',
    shouldStack: {count: true, total: true},
};
export const SEGMENT_LEAD_STATUS = {
    id: 'leadStatus',
    label: 'Outcome',
    shouldStack: {count: true, total: true},
};

export const SEGMENTS = [
    SEGMENT_SUBSCRIPTION_STATUS,
    SEGMENT_INTERACTION_TYPE,
    SEGMENT_PRODUCT,
    SEGMENT_SOURCE,
    SEGMENT_COMPETITOR,
    SEGMENT_ASSIGNEE,
    SEGMENT_USER,
    SEGMENT_STAGE,
    SEGMENT_STATUS,
    SEGMENT_TAG,
    SEGMENT_OUTCOME,
    SEGMENT_TERRITORY,
    SEGMENT_ACTIVITY_TYPE,
    SEGMENT_EMAIL_SEQUENCE_TEMPLATE_GROUP,
    SEGMENT_LEAD_STATUS,
    SEGMENT_CHANNEL,
];

/* Temporary activity report schema as it uses a couple fields that aren't
in the default leads schema we're using for other reports */
export const ACTIVITY_REPORT_SCHEMA = {
    participant: {
        name: 'participant',
        type: 'relationship',
        title: 'Users & teams',
        description: 'The participants on an activity',
        isFilterable: true,
        relationships: ['users', 'teams'],
        isSortable: false,
        isDisplayable: false,
    },
    activityType: {
        name: 'activityType',
        type: 'relationship',
        title: 'Activity type',
        description: 'The activity type of the activity',
        isFilterable: true,
        relationships: ['activityTypes'],
        isSortable: false,
        isDisplayable: false,
    },
};

/* Temporary email report schema as it uses a couple fields that aren't
in the default leads schema we're using for other reports */
export const EMAIL_REPORT_SCHEMA = {
    recipient: {
        name: 'recipient',
        type: 'relationship',
        title: 'Users & teams',
        description: 'The recipients or senders on this email',
        isFilterable: true,
        relationships: ['users', 'teams'],
        isSortable: false,
        isDisplayable: false,
    },
    emailSequenceTemplateGroup: {
        name: 'emailSequenceTemplateGroups',
        type: 'relationship',
        title: 'Email template group',
        description: 'The template group this email was sent from',
        isFilterable: true,
        nullOptionText: 'Uncategorized',
        relationships: ['emailSequenceTemplateGroups'],
        isSortable: false,
        isDisplayable: false,
    },
};

const DEFAULT_FIELDS_LOSSES = 'name,age,value,owner,milestone,confidence,closedTime,sources';
const DEFAULT_FIELDS_NEW_LEADS = 'name,age,value,owner,milestone,confidence,closedTime,sources';
const DEFAULT_FIELDS_SALES = 'name,age,value,owner,milestone,confidence,closedTime,sources';
const DEFAULT_FIELDS_CUSTOM = 'name,age,value,owner,milestone,confidence,closedTime,sources';
const DEFAULT_FIELDS_FORECAST = 'name,age,value,owner,milestone,confidence,closedTime,sources';
// TODO update this
const DEFAULT_FIELDS_ATTRIBUTION = 'name,age,value,owner,milestone,confidence,closedTime,sources';
const DEFAULT_FIELDS_AUDIENCE_MEMBERSHIP =
    'name,email,phone,engagementRating,tags,emailOpenRate,emailClickRate,marketingStatus';
const DEFAULT_FIELDS_EDITION_ENGAGEMENT = 'name,email,phone,engagementRating,tags,marketingStatus';
const DEFAULT_FIELDS_DRIP_EDITION_ENGAGEMENT =
    'name,email,phone,engagementRating,tags,marketingStatus';
const DEFAULT_FIELDS_FORM_ENGAGEMENT = 'name,createdTime';
export const DEFAULT_FIELDS_MCFXCOMPANIES =
    'name,matchedContacts,createdTime,region,mcfxIndustry,numberOfVisits,numberOfEmployees,revenue,phone,website,address,convertedTime';

// Chart bucket names
export const OTHER_BUCKET_NAME = 'Other';
export const NONE_BUCKET_NAME = 'None';

// Chart gap options
export const GAP_SELECT_VALUES = [
    {id: 'd', label: 'Day'},
    {id: 'w', label: 'Week'},
    {id: 'm', label: 'Month'},
    {id: 'q', label: 'Quarter'},
    {id: 'y', label: 'Year'},
];

// Chart gap options
export const EDITION_GAP_SELECT_VALUES = [
    {id: 'i', label: 'Minute'},
    {id: 'h', label: 'Hour'},
    {id: 'd', label: 'Day'},
];

export const ANALYTICS_GAP_SELECT_VALUES = [
    {id: 'd', label: 'Day'},
    {id: 'w', label: 'Week'},
    {id: 'm', label: 'Month'},
];

export const EDITION_GAP_INITIAL_VALUES = [
    {period: '+h1', gap: 'i'},
    {period: '+h4', gap: 'i'},
    {period: '+h12', gap: 'i'},
    {period: '+d1', gap: 'h'},
    {period: '+d2', gap: 'h'},
    {period: '+d7', gap: 'h'},
];

const leadsReportConstants = {
    [LOSSES]: {
        title: 'Losses',
        description: 'Why are we losing business?',
        icon: LossesIcon,
        documentTitle: 'Losses report · Nutshell',
        baseUrl: '/reports/losses',
        defaultFields: DEFAULT_FIELDS_LOSSES,
        defaultState: {
            search: `?report%5BdateRangeType%5D=closedTime&report%5Bgap%5D=w&report%5Bfacet%5D=total&filter%5BclosedTime%5D=-d365&filter%5Bstatus%5D=Lost%2CCancelled&list%5Bfields%5D%5Bleads%5D=${encodeURIComponent(
                DEFAULT_FIELDS_LOSSES
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
            dateRangeType: 'closedTime',
            dateRangeTypeFilter: LAST_365_DAYS.id,
        },
        dateRanges: DATE_RANGES_PAST,
        segmentOptions: [
            SEGMENT_NONE,
            SEGMENT_OUTCOME,
            SEGMENT_COMPETITOR,
            SEGMENT_PRODUCT,
            SEGMENT_ASSIGNEE,
            SEGMENT_TERRITORY,
            SEGMENT_SOURCE,
            SEGMENT_TAG,
            SEGMENT_CHANNEL,
        ],
        defaultSegment: NO_SEGMENT_ID,
        exportTypes: [LEAD_LIST_EXPORT_KEY, REPORT_DATA_EXPORT_KEY, REPORT_CHART_EXPORT_KEY],
        primaryFilters: [
            'owner',
            'closedTime',
            'competitors',
            'lastContactedTime',
            'createdTime',
            'outcome',
            'products',
            'stage',
            'stageset',
            'sources',
            'tags',
            'value',
        ],
        tooltipUnit: 'leads',
        filterFields: (fieldName) => !['status', 'closedTime'].includes(fieldName),
    },
    [NEW_LEADS]: {
        title: 'New leads',
        description: 'How many leads are we getting?',
        icon: NewLeadsIcon,
        documentTitle: 'New leads report · Nutshell',
        baseUrl: '/reports/newleads',
        defaultFields: DEFAULT_FIELDS_NEW_LEADS,
        defaultState: {
            search: `?report%5BdateRangeType%5D=createdTime&report%5Bgap%5D=w&report%5Bfacet%5D=total&filter%5BcreatedTime%5D=-d365&list%5Bfields%5D%5Bleads%5D=${encodeURIComponent(
                DEFAULT_FIELDS_NEW_LEADS
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
            dateRangeType: 'createdTime',
            dateRangeTypeFilter: LAST_365_DAYS.id,
        },
        dateRanges: DATE_RANGES_PAST,
        segmentOptions: [
            SEGMENT_NONE,
            SEGMENT_OUTCOME,
            SEGMENT_COMPETITOR,
            SEGMENT_PRODUCT,
            SEGMENT_ASSIGNEE,
            SEGMENT_TERRITORY,
            SEGMENT_SOURCE,
            SEGMENT_TAG,
            SEGMENT_CHANNEL,
        ],
        defaultSegment: NO_SEGMENT_ID,
        exportTypes: [LEAD_LIST_EXPORT_KEY, REPORT_DATA_EXPORT_KEY, REPORT_CHART_EXPORT_KEY],
        primaryFilters: [
            'owner',
            'closedTime',
            'lastContactedTime',
            'createdTime',
            'outcome',
            'products',
            'stage',
            'stageset',
            'sources',
            'tags',
            'value',
        ],
        tooltipUnit: 'leads',
        filterFields: (fieldName) => !['createdTime'].includes(fieldName),
    },
    [SALES]: {
        title: 'Sales',
        description: 'What are our sales this period?',
        icon: SalesIcon,
        documentTitle: 'Sales report · Nutshell',
        baseUrl: '/reports/sales',
        defaultFields: DEFAULT_FIELDS_SALES,
        defaultState: {
            search: `?report%5BdateRangeType%5D=closedTime&report%5Bgap%5D=w&report%5Bfacet%5D=total&filter%5BclosedTime%5D=-d365&filter%5Bstatus%5D=Won&list%5Bfields%5D%5Bleads%5D=${encodeURIComponent(
                DEFAULT_FIELDS_SALES
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
            dateRangeType: 'closedTime',
            dateRangeTypeFilter: LAST_365_DAYS.id,
        },
        dateRanges: DATE_RANGES_PAST,
        segmentOptions: [
            SEGMENT_NONE,
            SEGMENT_OUTCOME,
            SEGMENT_COMPETITOR,
            SEGMENT_PRODUCT,
            SEGMENT_ASSIGNEE,
            SEGMENT_TERRITORY,
            SEGMENT_SOURCE,
            SEGMENT_TAG,
            SEGMENT_CHANNEL,
        ],
        defaultSegment: NO_SEGMENT_ID,
        exportTypes: [LEAD_LIST_EXPORT_KEY, REPORT_DATA_EXPORT_KEY, REPORT_CHART_EXPORT_KEY],
        primaryFilters: [
            'owner',
            'closedTime',
            'lastContactedTime',
            'createdTime',
            'products',
            'sources',
            'stageset',
            'tags',
            'value',
        ],
        tooltipUnit: 'leads',
        filterFields: (fieldName) => !['status', 'closedTime'].includes(fieldName),
    },
    [CUSTOM]: {
        title: 'Custom',
        description: 'What do we know across all leads?',
        icon: CustomIcon,
        documentTitle: 'Custom report · Nutshell',
        baseUrl: '/reports/custom',
        defaultFields: DEFAULT_FIELDS_CUSTOM,
        defaultState: {
            search: `?report%5BdateRangeType%5D=createdTime&report%5Bgap%5D=w&report%5Bfacet%5D=total&filter%5BcreatedTime%5D=-d365&list%5Bfields%5D%5Bleads%5D=${encodeURIComponent(
                DEFAULT_FIELDS_CUSTOM
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
            dateRangeType: 'createdTime',
            dateRangeTypeFilter: LAST_365_DAYS.id,
        },
        dateRanges: DATE_RANGES_PAST,
        segmentOptions: [
            SEGMENT_NONE,
            SEGMENT_OUTCOME,
            SEGMENT_PRODUCT,
            SEGMENT_ASSIGNEE,
            SEGMENT_TERRITORY,
            SEGMENT_SOURCE,
            SEGMENT_TAG,
            SEGMENT_COMPETITOR,
            SEGMENT_STAGE,
            SEGMENT_STATUS,
            SEGMENT_CHANNEL,
        ],
        defaultSegment: NO_SEGMENT_ID,
        exportTypes: [LEAD_LIST_EXPORT_KEY, REPORT_DATA_EXPORT_KEY, REPORT_CHART_EXPORT_KEY],
        primaryFilters: [
            'closedTime',
            'createdTime',
            'lastContactedTime',
            'outcome',
            'owner',
            'products',
            'sources',
            'stage',
            'stageset',
            'status',
            'tags',
            'value',
        ],
        tooltipUnit: 'leads',
        filterFields: () => true, // This is controlled in components dynamicly based on dateRangeType
    },
    [FORECAST]: {
        title: 'Forecast',
        description: 'What are our expected sales?',
        icon: ForecastIcon,
        documentTitle: 'Forecast report · Nutshell',
        baseUrl: '/reports/forecast',
        defaultFields: DEFAULT_FIELDS_FORECAST,
        defaultState: {
            search: `?report%5BdateRangeType%5D=closedTime&report%5Bgap%5D=w&report%5Bfacet%5D=total&report%5BuseConfidence%5D=false&filter%5Bstatus%5D=Won%2COpen&filter%5BclosedTime%5D=%2Bd90&list%5Bfields%5D%5Bleads%5D=${encodeURIComponent(
                DEFAULT_FIELDS_FORECAST
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
            dateRangeType: 'closedTime',
            dateRangeTypeFilter: '+d90',
        },
        dateRanges: DATE_RANGES_FUTURE,
        segmentOptions: [
            SEGMENT_NONE,
            SEGMENT_PRODUCT,
            SEGMENT_ASSIGNEE,
            SEGMENT_SOURCE,
            SEGMENT_TAG,
            SEGMENT_COMPETITOR,
            SEGMENT_STAGE,
            SEGMENT_TERRITORY,
            SEGMENT_CHANNEL,
        ],
        defaultSegment: NO_SEGMENT_ID,
        exportTypes: [LEAD_LIST_EXPORT_KEY, REPORT_DATA_EXPORT_KEY, REPORT_CHART_EXPORT_KEY],
        primaryFilters: [
            'owner',
            'closedTime',
            'lastContactedTime',
            'createdTime',
            'outcome',
            'products',
            'stageset',
            'tags',
            'value',
        ],
        tooltipUnit: 'leads',
        filterFields: (fieldName) => !['status', 'closedTime'].includes(fieldName),
    },
    [ATTRIBUTION]: {
        title: 'Attribution',
        description: getAttributionReportDescription(),
        icon: AttributionIcon,
        documentTitle: 'Attribution report · Nutshell',
        baseUrl: '/reports/attribution',
        defaultFields: DEFAULT_FIELDS_ATTRIBUTION,
        defaultState: {
            search: `?report%5BdateRangeType%5D=period&report%5Bgap%5D=w&report%5Bfacet%5D=total&report%5BuseConfidence%5D=false&filter%5Bperiod%5D=-d90&list%5Bfields%5D%5Bleads%5D=${encodeURIComponent(
                DEFAULT_FIELDS_ATTRIBUTION
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
            dateRangeType: 'period',
            dateRangeTypeFilter: '-d90',
        },
        dateRanges: DATE_RANGES_ATTRIBUTION,
        segmentOptions: [],
        defaultSegment: NO_SEGMENT_ID,
        exportTypes: [],
        primaryFilters: [
            'owner',
            'lastContactedTime',
            'createdTime',
            'outcome',
            'products',
            'stageset',
            'tags',
            'value',
        ],
        tooltipUnit: 'leads',
        filterFields: (fieldName) =>
            !['status', 'period', 'createdTime', 'closedTime'].includes(fieldName),
    },
};

const funnelReportConstants = {
    [FUNNEL]: {
        title: 'Funnel',
        description: 'What are our conversion rates?',
        icon: FunnelIcon,
        documentTitle: 'Funnel report · Nutshell',
        baseUrl: '/reports/funnel',
        dateRangeType: 'closedTime',
        defaultState: {
            // This is a bit hacky, but the server handles the default search
            // params for funnel report
            search: '',
        },
        dateRanges: DATE_RANGES_PAST,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: 'leads',
        filterFields: (fieldName) => fieldName,
    },
};

const activityReportConstants = {
    [ACTIVITY]: {
        title: 'Activity',
        description: 'How are we performing against our goals?',
        icon: ActivityIcon,
        documentTitle: 'Activity report · Nutshell',
        baseUrl: '/reports/activity',
        defaultState: {
            search:
                '?filter[period]=-d90&report%5Bgap%5D=w&report%5BdateRangeType%5D=period&report%5Bfacet%5D=count&report%5Bsegment%5D=activityType',
            report: {
                gap: 'w',
                dateRangeType: 'period',
                facet: 'count',
            },
            sort: {
                by: 'User',
                direction: 'des',
            },
        },
        tooltipUnit: null,
        dateRanges: DATE_RANGES_PAST,
        segmentOptions: [SEGMENT_NONE, SEGMENT_USER, SEGMENT_ACTIVITY_TYPE, SEGMENT_LEAD_STATUS],
        defaultSegment: SEGMENT_ACTIVITY_TYPE,
        exportTypes: [REPORT_ACTIVITY_DETAILS_EXPORT_KEY, REPORT_ACTIVITY_OVERVIEW_EXPORT_KEY],
        filterFields: (fieldName) => fieldName,
    },
};

const emailReportConstants = {
    [EMAIL]: {
        title: 'Email',
        description: 'What kind of emails are we sending?',
        icon: EmailIcon,
        documentTitle: 'Email report · Nutshell',
        baseUrl: '/reports/email',
        defaultState: {
            search:
                '?filter[period]=-d90&report%5Bgap%5D=w&report%5BdateRangeType%5D=period&report%5Bfacet%5D=count&report%5Bsegment%5D=emailSequenceTemplateGroups',
            report: {
                gap: 'w',
                dateRangeType: 'period',
                facet: 'count',
            },
            sort: {
                by: 'User',
                direction: 'des',
            },
        },
        tooltipUnit: null,
        dateRanges: DATE_RANGES_PAST,
        segmentOptions: [SEGMENT_NONE, SEGMENT_USER, SEGMENT_EMAIL_SEQUENCE_TEMPLATE_GROUP],
        defaultSegment: SEGMENT_EMAIL_SEQUENCE_TEMPLATE_GROUP,
        exportTypes: [],
        filterFields: (fieldName) => fieldName,
    },
};

// TODO: Probably get these out of here
const emailMarketingReportConstants = {
    [AUDIENCE_MEMBERSHIP]: {
        title: 'Audience membership',
        description: '',
        documentTitle: '',
        icon: CustomIcon,
        baseUrl: '',
        defaultFields: DEFAULT_FIELDS_AUDIENCE_MEMBERSHIP,
        dateRangeType: 'period',
        defaultState: {
            search: `?filter%5Bperiod%5D=-d90&filter%5BincludeExisting%5D=0&report%5BdateRangeType%5D=period&report%5Bfacet%5D=count&report%5BreportType%5D=membership&report%5Bgap%5D=w&report%5Bsegment%5D=subscriptionStatus&list%5Bfields%5D%5Bcontacts%5D=${encodeURIComponent(
                DEFAULT_FIELDS_AUDIENCE_MEMBERSHIP
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
        },
        dateRanges: DATE_RANGES_PAST,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: null,
        filterFields: (fieldName) => fieldName,
    },
    [EDITION_ENGAGEMENT]: {
        title: 'Edition engagemnet',
        description: '',
        documentTitle: '',
        icon: CustomIcon,
        baseUrl: '',
        defaultFields: DEFAULT_FIELDS_EDITION_ENGAGEMENT,
        dateRangeType: 'period',
        defaultState: {
            search: `?filter%5Bperiod%5D=%2bh4&report%5BdateRangeType%5D=period&report%5Bfacet%5D=count&&report%5Bgap%5D=i&report%5Bsegment%5D=interactionType&list%5Bfields%5D%5Bcontacts%5D=${encodeURIComponent(
                DEFAULT_FIELDS_EDITION_ENGAGEMENT
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
        },
        dateRanges: DATE_RANGES_MINUTES,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: null,
        filterFields: (fieldName) => fieldName,
    },
    [DRIP_EDITION_ENGAGEMENT]: {
        title: 'Drip edition engagemnet',
        description: '',
        documentTitle: '',
        icon: CustomIcon,
        baseUrl: '',
        defaultFields: DEFAULT_FIELDS_DRIP_EDITION_ENGAGEMENT,
        dateRangeType: 'period',
        defaultState: {
            search: `?filter%5Bperiod%5D=-d30&report%5BdateRangeType%5D=period&report%5Bfacet%5D=count&&report%5Bgap%5D=d&report%5Bsegment%5D=interactionType&list%5Bfields%5D%5Bcontacts%5D=${encodeURIComponent(
                DEFAULT_FIELDS_DRIP_EDITION_ENGAGEMENT
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
        },
        dateRanges: DATE_RANGES_PAST,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: null,
        filterFields: (fieldName) => fieldName,
    },
    [FORM_ENGAGEMENT]: {
        title: 'Form engagemnet',
        description: '',
        documentTitle: '',
        icon: CustomIcon,
        baseUrl: '',
        defaultFields: DEFAULT_FIELDS_FORM_ENGAGEMENT,
        dateRangeType: 'period',
        defaultState: {
            search: `?filter%5Bperiod%5D=-d30&report%5BdateRangeType%5D=period&report%5Bfacet%5D=count&&report%5Bgap%5D=d&report%5Bsegment%5D=interactionType&list%5Bfields%5D%5Bcontacts%5D=${encodeURIComponent(
                DEFAULT_FIELDS_FORM_ENGAGEMENT
            )}&list%5Bpage%5D=1&list%5Bsort%5D=name`,
        },
        dateRanges: DATE_RANGES_FORMS,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: null,
        filterFields: (fieldName) => fieldName,
    },
    [SITE_ANALYTICS]: {
        title: 'Site analytics',
        description: '',
        documentTitle: '',
        icon: CustomIcon,
        baseUrl: '',
        defaultFields: null,
        dateRangeType: 'period',
        defaultState: {
            search: `?filter%5Bperiod%5D=-d30&report%5BdateRangeType%5D=period&report%5Bfacet%5D=count&&report%5Bgap%5D=d&report%5Bsegment%5D=interactionType`,
        },
        dateRanges: DATE_RANGES_ANALYTICS,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: null,
        filterFields: (fieldName) => fieldName,
    },
    [MCFXCOMPANIES]: {
        title: 'Visitors',
        description: '',
        documentTitle: '',
        icon: CustomIcon,
        baseUrl: '',
        defaultFields: DEFAULT_FIELDS_MCFXCOMPANIES,
        dateRangeType: 'period',
        defaultState: {
            search: `?filter%5Bperiod%5D=-d30&list%5Bfields%5D%mcfxcompanies%5D=${encodeURIComponent(
                DEFAULT_FIELDS_MCFXCOMPANIES
            )}&list%5Bsort%5D=-createdTime&list%5Bpage%5D=1`,
        },
        dateRanges: DATE_RANGES_FORMS,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: null,
        filterFields: (fieldName) => fieldName,
    },
    [SCHEDULER]: {
        title: 'Scheduler',
        description: '',
        documentTitle: '',
        icon: CustomIcon,
        baseUrl: '',
        defaultFields: null,
        dateRangeType: 'period',
        defaultState: {
            search:
                '?filter%5Bperiod%5D=-d30&report%5BdateRangeType%5D=period&report%5Bfacet%5D=count&&report%5Bgap%5D=d&report%5Bsegment%5D=interactionType',
        },
        dateRanges: DATE_RANGES_PAST,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: null,
        filterFields: (fieldName) => fieldName,
    },
    [COMPETITORS]: {
        title: 'CompetitorIQ',
        description: '',
        documentTitle: '',
        icon: CustomIcon,
        baseUrl: '',
        defaultFields: null,
        dateRangeType: 'period',
        defaultState: {
            search: '',
        },
        dateRanges: DATE_RANGES_ANALYTICS,
        defaultSegment: undefined,
        exportTypes: [],
        tooltipUnit: null,
        filterFields: (fieldName) => fieldName,
    },
};

const reportConstants = {
    ...leadsReportConstants,
    ...funnelReportConstants,
    ...activityReportConstants,
    ...emailReportConstants,
    ...emailMarketingReportConstants,
};

export function getReportTitle(reportKey: ReportKey): string {
    return reportConstants[reportKey].title || 'Report';
}

export function getReportDescription(reportKey: ReportKey): string | React.Node {
    return reportConstants[reportKey].description || '';
}

export function getReportFieldsFilterFunction(reportKey: ReportKey): (string) => boolean {
    // $FlowFixMe upgrading Flow to v0.92.1 on web
    return reportConstants[reportKey].filterFields;
}

export function getReportDocumentTitle(reportKey: ReportKey) {
    return reportConstants[reportKey].documentTitle;
}

export function getReportBaseUrl(reportKey: ReportKey) {
    return reportConstants[reportKey].baseUrl;
}

export function getReportDefaultSegment(reportKey: ReportKey) {
    return reportConstants[reportKey].defaultSegment;
}

export function getReportDefaultState(reportKey: ReportKey) {
    return reportConstants[reportKey].defaultState;
}

export function getPrimaryLeadsReportFilters(reportKey: LeadsReportKey) {
    return leadsReportConstants[reportKey].primaryFilters;
}

export function getShouldAllowSegmentation(reportKey: ReportKey) {
    return Boolean(getReportSegments(reportKey).length);
}

export function isEmailMarketingReportKey(reportKey: ReportKey) {
    // $FlowIgnore
    return typeof emailMarketingReportConstants[reportKey] !== 'undefined';
}
export function getReportSegmentDisplayType(reportKey: ReportKey) {
    return reportKey === EDITION_ENGAGEMENT ? 'line' : 'bar';
}

/**
 * Returns the name that the `/rest/report` api uses for the primary report for a given
 * reportKey.
 *
 * @param  {string} reportKey The identifier for the report type
 * @return {string}           Primary report name
 */
export function getApiReportName(reportKey: LeadsReportKey | FunnelReportKey): string {
    if (reportKey === FORECAST) {
        return 'Sales';
    }

    return 'Leads';
}

export function getReportKeyFromReportSavedType(reportSavedType: ReportSavedTypeKey): ReportKey {
    switch (reportSavedType) {
        case LOSSES_SAVED_TYPE:
            return LOSSES;
        case NEW_LEADS_SAVED_TYPE:
            return NEW_LEADS;
        case SALES_SAVED_TYPE:
            return SALES;
        case FORECAST_SAVED_TYPE:
            return FORECAST;
        case FUNNEL_SAVED_TYPE:
            return FUNNEL;
        case CUSTOM_SAVED_TYPE:
            return CUSTOM;
        case ACTIVITY_SAVED_TYPE:
            return ACTIVITY;
        case EMAIL_SAVED_TYPE:
            return EMAIL;
        default:
            return CUSTOM;
    }
}

export function getReportSaveType(reportKey: ReportKey): ReportSavedTypeKey {
    switch (reportKey) {
        case LOSSES:
            return LOSSES_SAVED_TYPE;
        case NEW_LEADS:
            return NEW_LEADS_SAVED_TYPE;
        case SALES:
            return SALES_SAVED_TYPE;
        case FORECAST:
            return FORECAST_SAVED_TYPE;
        case ATTRIBUTION:
            return ATTRIBUTION_SAVED_TYPE;
        case FUNNEL:
            return FUNNEL_SAVED_TYPE;
        case CUSTOM:
            return CUSTOM_SAVED_TYPE;
        case ACTIVITY:
            return ACTIVITY_SAVED_TYPE;
        case EMAIL:
            return EMAIL_SAVED_TYPE;
        default:
            return CUSTOM_SAVED_TYPE;
    }
}

export function getExportTypes(reportKey: ReportKey): Array<ExportKey> {
    return reportConstants[reportKey].exportTypes;
}

export function getReportSegments(reportKey: ReportKey): Array<SegmentOption> {
    // $FlowFixMe upgrading Flow to v0.92.1 on web
    return reportConstants[reportKey].segmentOptions || [];
}

export function getReportDefaultFields(reportKey: ReportKey): string {
    return reportConstants[reportKey].defaultFields || '';
}

export function getReportIcon(reportKey: ReportKey) {
    return reportConstants[reportKey].icon;
}

export function getTooltipUnit(reportKey: ReportKey) {
    return reportConstants[reportKey].tooltipUnit;
}

export function getReportDateRanges(reportKey: ReportKey) {
    return reportConstants[reportKey].dateRanges;
}
