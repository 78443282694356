/* @flow */

import type {UsersApiResponse, User} from '../types';
import type {SessionState} from './session-reducer';

export const REDUCER_KEY = 'session';

export type SessionModuleState = {|
    session: SessionState,
|};

export const ActionTypes = {
    SESSION_FETCH_SESSION_REQUEST: 'SESSION_FETCH_SESSION_REQUEST',
    SESSION_FETCH_SESSION_SUCCESS: 'SESSION_FETCH_SESSION_SUCCESS',
    SESSION_FETCH_SESSION_FAILURE: 'SESSION_FETCH_SESSION_FAILURE',
    SESSION_HACKY_DOM_USER_ADDED: 'SESSION_HACKY_DOM_USER_ADDED',
};

export type FetchSessionRequestAction = {|
    +type: 'SESSION_FETCH_SESSION_REQUEST',
    payload: {baseUrl: string, options?: RequestOptions},
|};
export type FetchSessionSuccessAction = {|
    +type: 'SESSION_FETCH_SESSION_SUCCESS',
    payload: UsersApiResponse,
|};
export type FetchSessionFailureAction = {|
    +type: 'SESSION_FETCH_SESSION_FAILURE',
    payload: ?Object,
    error: true,
|};
export type AddHackyDomUserAction = {|+type: 'SESSION_HACKY_DOM_USER_ADDED', payload: ?User|};

export type SessionAction =
    | FetchSessionRequestAction
    | FetchSessionSuccessAction
    | FetchSessionFailureAction
    | AddHackyDomUserAction;
