/* @flow */

// These are needed because unfortunately we seem to be ending up with multiple
// discrete copies of rxjs, and unless we add the operators here, sometimes
// they don't work.
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/debounceTime';

import * as ramda from 'ramda';
import {combineEpics} from 'redux-observable';

import {Accounts} from 'nutshell-core/accounts';
import {Billing} from 'nutshell-core/billing';
import * as Contacts from 'nutshell-core/contacts';
import {Session} from 'nutshell-core/session';
import {Tasks} from 'nutshell-core/tasks';
import * as AudienceEpics from './ui/email-marketing/audiences/audiences-epics';
import * as EditionEpics from './ui/email-marketing/editions/editions-epics';
import * as AnalyticsEpic from './ui/email-marketing/analytics/report/analytics-epics';
import * as ReportEpics from './ui/reports/reports-epics';
import * as WebFormEpics from './ui/forms/forms-epics';
import * as McfxCompaniesEpics from './ui/email-marketing/visitors/companies-list/companies-epics';

// $FlowFixMe - ramda - after typing these, everything blew up.  :-/
export const rootEpic = combineEpics(
    ...Accounts.epics,
    ...Billing.epics,
    ...Contacts.epics,
    ...Session.epics,
    ...Tasks.epics,
    ...ramda.values(ReportEpics),
    ...ramda.values(AudienceEpics),
    ...ramda.values(EditionEpics),
    ...ramda.values(WebFormEpics),
    ...ramda.values(AnalyticsEpic),
    ...ramda.values(McfxCompaniesEpics)
);
