/* @flow */

import * as React from 'react';
import {ScrollableContainerWithShadow} from 'shells/layout';

import {TimelineBannerModalWrapper} from '../../timeline/banner/timeline-banner-modal-wrapper';
import {SubmissionData} from './submission-data';

type Props = {
    onClose: () => void,
    submitter: Object,
    form: Object,
    submissionId: string,
    submissionTime: number,
};

export function ViewFormSubmissionModal(props: Props) {
    return (
        <TimelineBannerModalWrapper
            onClose={props.onClose}
            iconVariant='form-submitted'
            timestamp={props.submissionTime}
            actor={props.submitter}
            entryType='Submission'
            changeType='FORM_SUBMISSION_MAPPED'
            entity={props.form}
        >
            <ScrollableContainerWithShadow
                direction='vertical'
                maxContainerSize={600}
                showReverseGradientShadow={true}
                gradientShadowSize={60}
            >
                <div className='pad-32'>
                    <SubmissionData submissionId={props.submissionId} />
                </div>
            </ScrollableContainerWithShadow>
        </TimelineBannerModalWrapper>
    );
}
