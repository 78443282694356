/* @flow */

import React from 'react';

import {pillWrapper} from '../../acorn-pill/pill-wrapper';
import NutshellEnterprise from './icon-nutshell-enterprise.svg';

type Props = {
    width?: number,
    height?: number,
};

function SvgComponent(props: Props) {
    return <NutshellEnterprise width={props.width} height={props.height} />;
}
SvgComponent.iconName = 'nutshellEnterprise';

export const NutshellEnterpriseIcon = pillWrapper(SvgComponent);
