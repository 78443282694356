/* @flow */

import * as React from 'react';
import {SavedSettingToast} from '../saved-setting-toast';
import type {SavedSettingToastState} from '../saved-setting-toast';

import {UppercaseLabel} from '../typography';

type Props = {
    label: string,
    labelButton?: React.Node,
    sublabel?: string | React.Node,
    content: string | React.Node,
    spacing?: number,
    isFullHeight?: boolean,
    state?: SavedSettingToastState,
};

export function LabelWithContent(props: Props) {
    return (
        <div className={props.isFullHeight ? 'flex flex-dir-col full-height' : 'flex flex-dir-col'}>
            <div style={props.spacing ? {marginBottom: `${props.spacing}px`} : undefined}>
                <div className='flex align-center justify-sb'>
                    <SavedSettingToast state={props.state || 'initial'}>
                        <UppercaseLabel>{props.label}</UppercaseLabel>
                    </SavedSettingToast>
                    {props.labelButton}
                </div>
                {props.sublabel ? <div>{props.sublabel}</div> : undefined}
            </div>
            {props.content}
        </div>
    );
}
